import moment from "moment";
import validator from "validator";
import { encode } from "js-base64";
import toast from "react-hot-toast";
// import { Userpilot } from "userpilot";
import { AppContext } from "../../App";
import { LoadingButton } from "@mui/lab";
import { apiCallGet } from "../../utils/action";
import config_settings from "../../config/config";
import { useSearchParams } from "react-router-dom";
import logo from "../../../assets/images/logo.png";
import "../../../assets/styles/containers/_login.scss";
import { useEffect, useState, useContext } from "react";
import Visibility from "@mui/icons-material/Visibility";
// import LoginPreloader from "../../helpers/LoginPreloader";
import { useNavigate, useLocation } from "react-router-dom";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { apiCall } from "../../pages/settings/Services/action";
import UnbounceCreatePassword from "../../components/UnbounceCreatePassword/UnbounceCreatePassword";
import { Alert, Grid, Paper, TextField, Typography, InputLabel, IconButton, FormControl, useMediaQuery, InputAdornment } from "@mui/material";

let UNBOUNCE_URL;

const origin = () => {
  let origin = window.location.origin.toLowerCase();
  if (origin.includes("https://")) origin = origin.replace("https://", "");
  else origin = origin.replace("http://", "");
  UNBOUNCE_URL = config_settings[origin].UNBOUNCE_URL;
  console.log("UNBOUNCE_URL", UNBOUNCE_URL);
  // return origin;
};
origin();

export const Login = () => {
  const location = useLocation();
  const [email, setEmail] = useState("");
  const userData = location.state?.message;
  const { setReload } = useContext(AppContext);
  const [password, setPassword] = useState("");
  const [fromUnbounce, setFromUnbounce] = useState(false);
  const [unbounceToken, setUnbounceToken] = useState("");
  const [loading, setLoading] = useState(false);
  const matches = useMediaQuery("(min-width:900px)");
  const [emailerror, setemailError] = useState(false);
  const [randomString, setRandomString] = useState("");
  // const [unbounceToken, setUnbounceToken] = useState("");
  // const [fromUnbounce, setFromUnbounce] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [passworderror, setpasswordError] = useState(false);
  // const [autoLoginLoading, setAutoLoginLoading] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [unbouncePasswordError, setUnbouncePasswordError] = useState(false);

  let navigate = useNavigate();
  const routeChange = (roleId) => {
    roleId === 2 || roleId === 3 || roleId === 4 ? navigate(`/company`) : roleId === 10 || roleId === 11 ? navigate(`/import`) : navigate(`/dashboard`);
    setReload((prev) => !prev);
  };

  const validation = (email, password) => {
    if (validator.isEmail(email)) {
      setemailError(false);
    } else {
      setemailError(true);
    }
    if (validator.isStrongPassword(password)) {
      setpasswordError(false);
    } else {
      setpasswordError(true);
    }
    if (validator.isEmail(email) && validator.isStrongPassword(password)) {
      loginuser(email, password);
    }
  };

  const validationUnbounce = (pass) => {
    if (validator.isStrongPassword(pass)) {
      setUnbouncePasswordError(false);
    } else {
      setUnbouncePasswordError(true);
    }
    if (validator.isStrongPassword(pass)) {
      tokenLogin(unbounceToken, pass);
    }
  };

  const GetRandomStr = () => {
    setLoading(true);
    apiCallGet(
      (response) => {
        if (response.data.success) {
          // success
          setLoading(false);
          setRandomString(response.data.data);
        } else {
          setLoading(false);
          toast.error(response.data.message);
          // console.log("else", response.data.message);
        }
      },
      "",
      "RANDOMSTR"
    );
  };

  useEffect(() => {
    GetRandomStr();
  }, []);

  const tokenLogin = (tok, pass) => {
    // setAutoLoginLoading(true);
    setLoading(true);
    const formdata = {
      token: tok,
      password: randomString + encode(pass),
      is_encryption: 1,
    };
    apiCall(
      (response) => {
        const { success, data, message } = response.data;
        if (success) {
          sessionStorage.setItem("token", data.token);
          sessionStorage.setItem("user_id", data.user_id);
          sessionStorage.setItem("user_name", data.user_name);
          sessionStorage.setItem("last_name", data.last_name);
          sessionStorage.setItem("first_name", data.first_name);
          sessionStorage.setItem("company_id", data.company_id);
          sessionStorage.setItem("is_from_us", data.is_from_us);
          sessionStorage.setItem("role_id", data.role_data.role_id);
          sessionStorage.setItem("total_insp", data.total_inspections);
          sessionStorage.setItem("is_trial_company", data.is_trial_company);
          sessionStorage.setItem("profile", data.company_profile);
          sessionStorage.setItem("is_trial", data.is_trial);
          sessionStorage.setItem("can_create_inspection", data.can_create_inspection);
          // PALM 10151 
          // Userpilot.identify(data.user_id, {
          //   name: data.user_name,
          //   email: email,
          //   created_at: new Date(),
          //   palm_role_id: data.role_data.role_id,
          //   show_user_pilot: data.show_user_pilot,
          // });
          getCompanyInfo(data.company_id, data.user_id, data.role_data.role_id, data.role_data.role_id, data.role_data.role_display_name);
          // setAutoLoginLoading(false);
          setLoading(false);
        } else {
          toast.error(message);
          setLoading(false);
          // setAutoLoginLoading(false);
          console.log("else", message);
        }
      },
      formdata,
      "TOKEN_LOGIN"
    );
  };

  const getCompanyInfo = (company_id, user_id, role_id, route, role) => {
    const formdata = {
      company_id: company_id,
      user_id: user_id,
      role_id: role_id,
    };
    apiCall(
      (response) => {
        console.log("GET_COMPANY_INFO", response);
        if (response.data.success) {
          sessionStorage.setItem("company_name", response.data.data.company_details.name);
          sessionStorage.setItem("show_banner", response.data.data.company_details.show_banner);
          sessionStorage.setItem("is_trial", response.data.is_trial);
          routeChange(route);
          toast.success(`Logged in as ${role}`);
        } else {
          console.log("else", response.data.message);
        }
      },
      formdata,
      "GET_COMPANY_INFO"
    );
  };

  //login user function
  const loginuser = (email, password) => {
    setLoading(true);
    toast.dismiss();
    const formdata = {
      userEmail: randomString + encode(email),
      password: randomString + encode(password),
      is_encryption: 1,
    };
    apiCall(
      (response) => {
        const { status_code, data, success, message } = response.data;
        if (status_code === 201) {
          sessionStorage.setItem("user_id", data.user_id);
          navigate("/auth");
        }
        if (success && status_code === 200) {
          sessionStorage.setItem("token", data.token);
          sessionStorage.setItem("user_id", data.user_id);
          sessionStorage.setItem("user_name", data.user_name);
          sessionStorage.setItem("last_name", data.last_name);
          sessionStorage.setItem("first_name", data.first_name);
          sessionStorage.setItem("company_id", data.company_id);
          sessionStorage.setItem("is_from_us", data.is_from_us);
          sessionStorage.setItem("role_id", data.role_data.role_id);
          sessionStorage.setItem("total_insp", data.total_inspections);
          sessionStorage.setItem("is_trial_company", data.is_trial_company);
          sessionStorage.setItem("profile", data.company_profile);
          sessionStorage.setItem("is_trial", data.is_trial);
          sessionStorage.setItem("can_create_inspection", data.can_create_inspection);
          // PALM 10151
          // Userpilot.identify(data.user_id, {
          //   name: data.user_name,
          //   email: email,
          //   created_at: new Date(),
          //   palm_role_id: data.role_data.role_id,
          //   show_user_pilot: data.show_user_pilot,
          // });
          window.pendo.initialize({
            visitor: {
              id: data.user_id,
              Name: data.user_name,
              Address: "",
              Email: email,
              Role: data.role_data.role_id !== null && data.role_data.role_id !== undefined ? (data.role_data.role_id === 5 ? "Owner" : data.role_data.role_id === 6 ? "Admin" : data.role_data.role_id === 7 ? "Inspector" : "") : "",
            },
            account: {
              id: data.company_id,
              ParentCompany: "PalmTech",
              BillingStartDate: data?.pendo?.subscription_start_date ? moment(moment.utc(data?.pendo?.subscription_start_date).local()).format("YYYY-MM-DD HH:mm:ss") : "",
              SubscriptionType: data?.pendo?.subscription_type,
              TrialStartDate: data?.pendo?.profile_creation_date ? moment(moment.utc(data?.pendo?.profile_creation_date).local()).format("YYYY-MM-DD HH:mm:ss") : "",
            },
          });
          window.Intercom('boot', {
            app_id: 'x06n6pzl',
            email: email,
            name: data.user_name,
          });
          getCompanyInfo(data.company_id, data.user_id, data.role_data.role_id, data.role_data.role_id, data.role_data.role_display_name);
        } else {
          console.log("else", message);
          toast.error(message);
          setLoading(false);
        }
      },
      formdata,
      "LOGIN"
    );
  };

  useEffect(() => {
    if (searchParams.has("token")) {
      // tokenLogin(searchParams.get("token"));
      setFromUnbounce(true);
      setUnbounceToken(searchParams.get("token"));
      searchParams.delete("token");
      setSearchParams(searchParams);
    }
    // eslint-disable-next-line
  }, []);

  //render
  return (
    <>
      {sessionStorage.getItem("token") ? (
        navigate("/dashboard")
      ) : (
        <>
          {/* background div for mobile and desktop*/}
          {fromUnbounce ? (
            <div className={`${matches ? "dashboard" : "dashboard-mobile"}`}>
              <UnbounceCreatePassword loading={loading} validationUnbounce={validationUnbounce} unbouncePasswordError={unbouncePasswordError} />
            </div>
          ) : (
            <div className={`${matches ? "background" : "background-mobile"}`}>
              <Grid container direction="column" alignItems="center" justifyContent="center">
                {/* palm tech logo */}
                <img className={`${matches ? "logo" : "logo-mobile"}`} src={logo} alt="logo"></img>
                {/* login component starts from here */}
                <Grid item xs={3}>
                  <Paper
                    className="paper-normal"
                    sx={{
                      minWidth: { xs: 370, md: 390, lg: 390 },
                      minHeight: { xs: 441, md: 414, lg: 414 },
                      boxShadow: "0 30px 30px 0 rgba(40, 40, 40, 0.4)",
                    }}
                  >
                    <Grid className="center">
                      <Typography
                        className="header"
                        sx={{
                          mt: "23px",
                          fontSize: { xs: "24px", md: "22px", lg: "22px" },
                        }}
                      >
                        Log in
                      </Typography>
                    </Grid>
                    {userData ? (
                      <Grid className="center" sx={{ p: 2, mb: -4, maxWidth: 390 }}>
                        <Alert severity="info">{userData}</Alert>
                      </Grid>
                    ) : (
                      ""
                    )}
                    {/* Input field for email*/}
                    <Grid className="center">
                      <FormControl variant="standard">
                        <InputLabel
                          htmlFor="component-simple"
                          className="input_lable"
                          sx={{
                            top: { xs: "18px", md: "17px", lg: "15px" },
                            fontSize: { xs: "14px", md: "12px", lg: "12px" },
                          }}
                        >
                          EMAIL ADDRESS
                        </InputLabel>
                        <TextField
                          id="email"
                          type="email"
                          className="input_field"
                          variant="outlined"
                          error={emailerror}
                          placeholder="Enter email"
                          size={`${matches ? "small" : "medium"}`}
                          onChange={(event) => setEmail(event.target.value)}
                          onKeyPress={(e) => {
                            if (e.key === "Enter" && !loading) validation(e.target.value, password);
                          }}
                          helperText={`${emailerror === true ? "Email address not found. Try again or create a new account." : ""}`}
                          sx={{
                            marginTop: "60px",
                            width: { xs: "340px", md: "317px", lg: "317px" },
                          }}
                          InputProps={{
                            className: "input_props_field",
                            sx: {
                              height: { xs: "55px", md: "40px", lg: "40px" },
                              fontSize: { xs: "18px", md: "14px", lg: "14px" },
                            },
                          }}
                        />
                      </FormControl>
                    </Grid>
                    {/* input field for password *************************************************************************************************** */}
                    <Grid className="center">
                      <FormControl variant="standard">
                        <InputLabel
                          htmlFor="component-simple"
                          className="input_lable"
                          sx={{
                            top: "1px",
                            fontSize: { xs: "14px", md: "12px", lg: "12px" },
                          }}
                        >
                          PASSWORD
                        </InputLabel>
                        <TextField
                          id="password"
                          className="input_field"
                          variant="outlined"
                          error={passworderror}
                          placeholder="Password"
                          size={`${matches ? "small" : "medium"}`}
                          type={showPassword ? "text" : "password"}
                          value={password}
                          onChange={(event) => setPassword(event.target.value?.trim())}
                          onKeyPress={(e) => {
                            if (e.key === "Enter" && !loading) validation(email, e.target.value);
                          }}
                          helperText={`${passworderror === true ? "Incorrect password." : ""}`}
                          sx={{
                            marginTop: "45px",
                            width: { xs: "340px", md: "317px", lg: "317px" },
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton sx={{ mr: "-9px" }} onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            ),
                            className: "input_props_field",
                            sx: {
                              height: { xs: "55px", md: "40px", lg: "40px" },
                              fontSize: { xs: "18px", md: "14px", lg: "14px" },
                            },
                          }}
                        />
                      </FormControl>
                    </Grid>
                    {/* login in button  */}
                    <Grid className="center">
                      <LoadingButton
                        className="login_button"
                        sx={{
                          height: { xs: 42, md: 34, lg: 34 },
                          width: { xs: 180, md: 119, lg: 119 },
                          fontSize: { xs: 16, md: 13, lg: 13 },
                        }}
                        size="small"
                        variant="contained"
                        loading={loading}
                        onClick={() => validation(email, password)}
                      >
                        Log in
                      </LoadingButton>
                    </Grid>
                    {/* Forgot password hyperlink  */}
                    <Grid className="center">
                      {/*  eslint-disable-next-line */}
                      <a onClick={() => navigate("/forgot-password")}>
                        <Typography
                          className="link_forgotpass"
                          fontSize={{
                            xs: "16px",
                            md: "12px",
                            lg: "12px",
                            xl: "12px",
                          }}
                        >
                          Forgot password
                        </Typography>
                      </a>
                    </Grid>
                    {/* Sign up hyperlink  */}
                    <Grid className="center">
                      <Typography
                        className="link_nologin"
                        fontSize={{
                          xs: "16px",
                          md: "12px",
                          lg: "12px",
                          xl: "12px",
                        }}
                      >
                        No account?&nbsp;
                      </Typography>
                      {/*  eslint-disable-next-line */}
                      {/* <a onClick={() => navigate("/signup")}> */}
                      <Typography
                        onClick={() => window.open(UNBOUNCE_URL, "_blank")}
                        className="link_signup"
                        fontSize={{
                          xs: "16px",
                          md: "12px",
                          lg: "12px",
                          xl: "12px",
                        }}
                      >
                        Sign up here
                      </Typography>
                      {/* </a> */}
                    </Grid>
                  </Paper>
                  {/* Footer starts from here */}
                  <Grid
                    className="center"
                    sx={{
                      position: "abosolute",
                      bottom: "30px",
                      marginTop: { xs: "123px", md: "41px", lg: "41px" },
                      marginBottom: { xs: "0px", md: "25px", lg: "25px" },
                    }}
                  >
                    <div className="footer">
                      <p style={{ fontSize: "14px" }}>© 2023 Palmtech&nbsp;</p>
                      <a href="https://www.palm-tech.com/" rel="noreferrer" target="_blank" style={{ marginBottom: "-10px" }} className="footer">
                        www.palmtech.com
                      </a>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          )}
        </>
      )}
    </>
  );
};
