const config_settings = {
  "localhost:3000": {
    ENV: "DEV",
    IR_URL: "https://develop.myinspection.report/",
    RW_URL: "https://develop.report-writer.yourinspection.app/",
    UNBOUNCE_URL: "https://try.palmtech.com/free-trial/",
    SERVER_URL: "https://dev-portal-api.yourinspection.app/public/index.php/api",
    REPORT_SETTING_URL: "https://dev-report-api.myinspection.report/public/index.php/api",
  },
  "localhost:3001": {
    ENV: "INT",
    IR_URL: "https://integration.myinspection.report/",
    RW_URL: "https://integration.report-writer.yourinspection.app/",
    UNBOUNCE_URL: "https://try.palmtech.com/free-trial/",
    SERVER_URL: "https://int-portal-api.yourinspection.app/public/index.php/api",
    REPORT_SETTING_URL: "https://dev-report-api.myinspection.report/public/index.php/api",
  },
  "localhost:3002": {
    ENV: "DEV",
    IR_URL: "https://develop.myinspection.report/",
    RW_URL: "https://develop.report-writer.yourinspection.app/",
    UNBOUNCE_URL: "https://try.palmtech.com/free-trial/",
    SERVER_URL: "https://dev-portal-api.yourinspection.app/public/index.php/api",
    REPORT_SETTING_URL: "https://dev-report-api.myinspection.report/public/index.php/api",
  },
  "develop.palmtech.com": {
    ENV: "DEV",
    IR_URL: "https://develop.myinspection.report/",
    RW_URL: "https://develop.report-writer.yourinspection.app/",
    UNBOUNCE_URL: "https://try.palmtech.com/free-trial/",
    SERVER_URL: "https://palmtechdevelop.yourinspection.report/public/index.php/api",
    REPORT_SETTING_URL: "https://dev-report-api.myinspection.report/public/index.php/api",
  },
  "develop.palmtech.dev": {
    ENV: "DEV",
    IR_URL: "https://develop.myinspection.report/",
    RW_URL: "https://develop.report-writer.yourinspection.app/",
    UNBOUNCE_URL: "https://try.palmtech.com/free-trial/",
    SERVER_URL: "https://dev-portal-api.yourinspection.app/public/index.php/api",
    REPORT_SETTING_URL: "https://dev-report-api.myinspection.report/public/index.php/api",
  },
  "integration.dci019jvejs84.amplifyapp.com": {
    ENV: "INT",
    IR_URL: "https://integration.myinspection.report/",
    RW_URL: "https://integration.report-writer.yourinspection.app/",
    UNBOUNCE_URL: "https://try.palmtech.com/free-trial/",
    SERVER_URL: "https://int-portal-api.yourinspection.app/public/index.php/api",
    REPORT_SETTING_URL: "https://dev-report-api.myinspection.report/public/index.php/api",
  },
  "integration.palmtech.dev": {
    ENV: "INT",
    IR_URL: "https://integration.myinspection.report/",
    RW_URL: "https://integration.report-writer.yourinspection.app/",
    UNBOUNCE_URL: "https://try.palmtech.com/free-trial/",
    SERVER_URL: "https://int-portal-api.yourinspection.app/public/index.php/api",
    REPORT_SETTING_URL: "https://int-report-api.myinspection.report/public/index.php/api",
  },
  "staging.palmtech.com": {
    ENV: "STAGE",
    IR_URL: "https://staging.myinspection.report/",
    RW_URL: "https://staging.report-writer.yourinspection.app/",
    UNBOUNCE_URL: "https://try.palmtech.com/free-trial/",
    SERVER_URL: "https://stg-portal-api.palmtech.com/public/index.php/api",
    REPORT_SETTING_URL: "https://stg-report-api.myinspection.report/public/index.php/api",
  },
  "portal.palmtech.com": {
    ENV: "PROD",
    IR_URL: "https://myinspection.report/",
    RW_URL: "https://report-writer.yourinspection.app/",
    UNBOUNCE_URL: "https://try.palmtech.com/free-trial/",
    SERVER_URL: "https://prod-portal-api.palmtech.com/public/index.php/api",
    REPORT_SETTING_URL: "https://prod-report-api.myinspection.report/public/index.php/api",
  },
  "$PRID-ngp.palmtechpr.com": {
    //// Use this code of block for PR Testing environment ////
    ENV: "INT",
    IR_URL: "https://integration.myinspection.report/",
    RW_URL: "https://integration.report-writer.yourinspection.app/",
    UNBOUNCE_URL: "https://try.palmtech.com/free-trial/",
    SERVER_URL: "https://int-portal-api.yourinspection.app/public/index.php/api",
    // SERVER_URL: "https://pr-179-ngapi.palmtechpr.com/api",
    REPORT_SETTING_URL: "https://int-report-api.myinspection.report/public/index.php/api",
  },
};

export default config_settings;
