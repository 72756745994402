import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import toast from "react-hot-toast";
import { apiCall } from "../../utils/action";

const Placeholder = (props) => {
  const [placeholderValues, setPlaceHolderValues] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const dataFiltered = filterData(searchQuery, placeholderValues);
  // session datas
  const companyId = sessionStorage.getItem("company_id");
  const roleId = sessionStorage.getItem("role_id");
  const uuId = sessionStorage.getItem("user_id");
  // let dragableEl = [];

  // drag function
  // const handleDragPlaceholder = () => {
  //   const dragableEl = document.querySelectorAll(".draggable");
  //   const containerEl = document.querySelector(".sun-editor");
  //   dragableEl.forEach((draggable) => {
  //     draggable.addEventListener("dragstart", () =>
  //       draggable.classList.add("dragging")
  //     );
  //   });
  //   if (containerEl !== null) {
  //     containerEl.addEventListener("dragover", (e) => {
  //       e.preventDefault();
  //       const activeDrag = document.querySelector(".dragging span");
  //       dragableEl.forEach((draggable) => {
  //         draggable.addEventListener("dragend", (event) => {
  //           if (draggable.classList.contains("dragging")) {
  //             e.target.click();
  //             event.target.click();
  //           }
  //           draggable.classList.remove("dragging");
  //         });
  //       });
  //     });
  //   }
  // };

  // get placeholder values
  const getPlaceHolderValues = () => {
    props.setShowLoader(true);
    const formdata = {
      company_id: companyId,
      user_id: uuId,
      role_id: roleId,
      agreement: props.isAgreementPlaceholder ? 1 : 0,
    };
    apiCall(
      (response) => {
        const { data, message, success } = response.data;
        if (success) {
          setPlaceHolderValues(data);
          props.setShowLoader(false);
        } else {
          toast.error(message);
          props.setShowLoader(false);
          console.log("else ", response.data.message);
        }
      },
      formdata,
      "GET_AGREEMENT_PLACEHOLDERS"
    );
  };
  useEffect(() => {
    getPlaceHolderValues();
  }, []);

  function filterData(query, data) {
    if (!query) {
      return data;
    } else {
      return data.filter((d) => d.placeholder_key.toLowerCase().includes(query.toLowerCase()));
    }
  }
  const handleInsertPlaceholder = (placeholder) => {
    const editorInstance = props.editor.current.editor;
    const cursorPosition = editorInstance.selection.get();
    if (cursorPosition) {
      editorInstance.html.insert(` ${placeholder} `, true);
    }
  };

  return (
    <div className="sticky-mergeField">
      <div className="placeholder-head">Insert placeholders</div>
      <div className="placeholder-search">
        <TextField
          id="search-bar"
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
          variant="outlined"
          placeholder="Search merge field"
          size="small"
        />
        <SearchIcon className="search-merge" />
      </div>
      <ul className="placeholder">
        {dataFiltered.length !== 0 ? (
          dataFiltered?.map((placeholder) => (
            <Button className="placeholder-list" key={placeholder} onClick={() => handleInsertPlaceholder(placeholder.placeholder_value)}>
              <span id={placeholder.placeholder_value} draggable="false">
                <AddIcon /> {placeholder.placeholder_key}
              </span>
            </Button>
          ))
        ) : (
          <li className="placeholder-list">
            <p style={{ textAlign: "center" }}>No matches...</p>
          </li>
        )}
      </ul>
    </div>
  );
};

export default Placeholder;
