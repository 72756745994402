import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import toast from "react-hot-toast";
import CategoryLists from "./CategoryLists";
import TemplateAdd from "../../../common/TemplateUtils/TemplateAdd";
import NewCategory from "../TemplateModals/NewCategory";
import MainTemplateModal from "../TemplateModals/MainTemplateModal";
import { apiCall } from "../../../utils/action";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import uuid from "react-uuid";
import { animate } from "motion";
import { copyCategory } from "./CopyMetaData";
import { timestamp } from "../../../utils/services";
import { AnimatePresence, motion } from "framer-motion";
import { updateCategoryData } from "../../../store/action";
import { useDispatch, useSelector } from "react-redux";

const InspectionDetails = (props) => {
  const {
    CategoryList,
    AllCategoryList,
    setAllCategoryList,
    newList,
    setNewList,
    LoadList,
    checkActiveFocus,
    isFocused,
    updateTemplate,
    renId,
    renamingId,
    isChanged,
    setSkeletonLoad,
    skeletonLoad,
    activeRename,
    outsideClick,
    TriggerClick,
    templateId,
    setShowLoader,
    requestUpdate,
    getCategoryList,
    updateTemplateCallRef,
  } = props;

  const [OpenCategory, setOpenCategory] = useState(false);
  const [sel, setSel] = useState([]);
  const [descArray, setDescArray] = useState([]);
  const [checked, setChecked] = React.useState();
  const [newCatID, setNewCatId] = useState(false);
  const [active, setActive] = useState(false);
  const [id, setId] = useState("");
  const [categoryActive, setCategoryActive] = useState([]);
  const categories = useSelector((state) => state.templates)?.categoryList;
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (AllCategoryList?.template?.categories?.length > 0) {
      setDescArray((prev) => {
        const newCategoryList = [...AllCategoryList.template.categories];
        return newCategoryList;
      });
    } else {
      setDescArray([]);
    }
  }, [AllCategoryList]);

  const handleChanges = (index) => {
    const newItems = [...newList];

    newItems.map((item) => {
      if (item.uuid === index) {
        item.is_selected = !item.is_selected;
      }
    });

    // newItems[index].is_selected = !newItems[index].is_selected;
    setNewList(newItems);
    // category.is_selected = e.target.checked;
    // setChecked(e.target.checked);
    setSel(newList);
  };

  const handleAddCategory = () => {
    const currentId = uuid();
    const blankCategory = {
      uuid: currentId,
      prompt: "",
      introductory_text: null,
      is_selected: false,
      order: AllCategoryList.template.categories.length + 1,
      is_deleted: 0,
    };
    const formdata = {
      company_id: sessionStorage.getItem("company_id"),
      user_id: sessionStorage.getItem("user_id"),
      role_id: sessionStorage.getItem("role_id"),
      template_id: templateId,
    };
    apiCall(
      (response) => {
        const { data, message, success } = response.data;
        if (success) {
          setNewList([blankCategory, ...data]); // adding blank category object and category list from api response together to the state
          setShowLoader(false);
        } else {
          toast.error(response.data.message);
          setShowLoader(false);
        }
      },
      formdata,
      "GET_CATEGORIES_LIST"
    );
    setOpenCategory(!OpenCategory);
    // setNewList([blankCategory, ...newList])
    // newList.unshift(blankCategory);
    for (let i = 0; i < newList.length; i++) {
      newList[i].is_selected = false;
    }
    setChecked();
    setSel([]);
  };

  const handleCloseCat = () => {
    newList.splice(0, 1);
    setOpenCategory(!OpenCategory);
    setChecked();
    setSel([]);
  };

  const AddSiteDetaill = async () => {
    updateTemplateCallRef.current = [
      {
        status: false,
        message: "",
        toastFlag: "",
        categoryFunctionCall: undefined,
      },
    ];
    const newArr = sel.filter((cat) => {
      return cat.is_selected === true;
    });
    if (newArr.length > 0) {
      newList.splice(0, 1);
      const newArrWithData = newArr.map((categoryToBeCopied) => {
        if (categoryToBeCopied.category_id === undefined) {
          return { ...categoryToBeCopied, updated_at: timestamp(), is_selected: false };
        }
        for (let i = 0; i < AllCategoryList.template.categories.length; i++) {
          if (AllCategoryList.template.categories[i].prompt === categoryToBeCopied.prompt) {
            return copyCategory(AllCategoryList.template.categories[i], AllCategoryList.template.categories, "add");
          }
        }
      });
      const newCategories = descArray.concat(newArrWithData);
      dispatch(updateCategoryData(categories.concat(newArrWithData)));
      await requestUpdate();
      await getCategoryList();
      setAllCategoryList((prev) => {
        const newCategoryList = { ...AllCategoryList };
        newCategoryList.template.categories = newCategories;
        newCategoryList.template.categories.updated_at = timestamp();
        return newCategoryList;
      });
      setSel([]);
      setChecked();
      setOpenCategory(!OpenCategory);
      setCategoryActive([]);
    } else {
      newList.splice(0, 1);
      setOpenCategory(!OpenCategory);
      setChecked();
    }
  };
  document.querySelectorAll("section > div").forEach((item) => {
    animate((item, { opacity: [0, 1, 1, 0] }), {
      target: item,
      offset: ["start end", "end end", "start start", "end start"],
    });
  });

  const reorder = (data, startIndex, endIndex) => {
    const result = Array.from(data);
    let [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    const inspectionDetailsIndex = result.findIndex((item) => item.is_inspection_details === 1);
    [removed] = result.splice(inspectionDetailsIndex, 1);
    result.splice(0, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const reorderedItems = reorder(descArray, result.source.index, result.destination.index);
    reorderedItems.forEach((x, i) => {
      x.order = i + 1;
      x.updated_at = timestamp();
      return x;
    });
    setDescArray(reorderedItems);
    setAllCategoryList((prev) => {
      const newCategoryList = { ...AllCategoryList };
      newCategoryList.template.categories = reorderedItems;
      newCategoryList.template.categories.updated_at = timestamp();
      return newCategoryList;
    });
    const updatedItems = reorderedItems.map((item) => ({ ...item, lines: [] }));
    dispatch(updateCategoryData(updatedItems));
    updateTemplate("noToast");
  };

  const disableDnD = (isOpen, id) => {
    setActive(isOpen);
    setId(id);
    const newData = [...categoryActive];
    const index = newData.indexOf(id);
    if (index === -1) newData.push(id);
    else newData.splice(index, 1);
    setCategoryActive(newData);
  };

  return (
    <>
      <Grid className="inspection">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="InspDetails">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                <AnimatePresence initial={false}>
                  {descArray.map((lists, index) => {
                    return lists?.is_deleted ? (
                      ""
                    ) : (
                      <motion.div
                        key={lists?.uuid}
                        onAnimationStart={() => setSkeletonLoad(false)}
                        initial={{ opacity: 0, scale: 1, y: -50 }}
                        animate={{ opacity: 1, scale: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                        exit={{ opacity: 0, scale: 1, x: -50 }}>
                        <Draggable draggableId={String(lists?.uuid)} isDragDisabled={active && lists?.uuid === id} key={lists?.uuid} index={index}>
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.draggableProps}>
                              {
                                <CategoryLists
                                  disableDnD={(isOpen, id) => disableDnD(isOpen, id)}
                                  dragHandleProps={provided.dragHandleProps}
                                  isFocused={isFocused}
                                  checkActiveFocus={checkActiveFocus}
                                  AllCategoryList={AllCategoryList}
                                  setAllCategoryList={setAllCategoryList}
                                  category={lists}
                                  isChanged={isChanged}
                                  id={id}
                                  setNewCatId={setNewCatId}
                                  newCatID={newCatID}
                                  indexDup={index}
                                  arrLength={descArray?.length}
                                  renId={renId}
                                  skeletonLoad={skeletonLoad}
                                  setSkeletonLoad={setSkeletonLoad}
                                  isInspection={lists?.is_inspection_details}
                                  renamingId={(id) => renamingId(id)}
                                  activeRename={activeRename}
                                  TriggerClick={TriggerClick}
                                  outsideClick={outsideClick}
                                  updateTemplate={updateTemplate}
                                  LoadList={LoadList}
                                  categoryActive={categoryActive}
                                />
                              }
                            </div>
                            // </AnimatePresence>
                          )}
                        </Draggable>
                      </motion.div>
                    );
                  })}
                </AnimatePresence>
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <TemplateAdd HandleCreateSite={handleAddCategory} name={"Add category"} />
      </Grid>
      <MainTemplateModal
        newList={newList}
        createNewSite={OpenCategory}
        title={"Add category"}
        type={"newcat"}
        HandleCreateSite={handleCloseCat}
        actions={{
          AddSiteDetaill: () => AddSiteDetaill(),
        }}
        innerTemplate={<NewCategory newList={newList} handleChange={(index) => handleChanges(index)} checked={checked} />}
      />
    </>
  );
};

export default InspectionDetails;
