import "./App.scss";
import Router from "./router/router";
// PALM 10151
// import { Userpilot } from "userpilot";
// import userpilot from "userpilot";
import { apiCall } from "./utils/action";
import { HashRouter } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { Toaster, ToastBar, toast } from "react-hot-toast";
import { useState, useEffect, createContext } from "react";
import axios from "axios";
import moment from "moment";
import { Provider } from "react-redux";
import store from "./store/index";

export const AppContext = createContext();

function App() {
  // PALM 10151
  //Initialize Userpilot
  // Userpilot.initialize("NX-a88aa165");
  // Userpilot.anonymous();
  const [reload, setReload] = useState(false);
  let location = window.location.href;
  const uuId = sessionStorage.getItem("user_id");
  const roleId = sessionStorage.getItem("role_id");
  const [companyInfo, setCompanyInfo] = useState({});
  const companyId = sessionStorage.getItem("company_id");

  const getCompanyInfo = () => {
    const formdata = { company_id: companyId, user_id: uuId, role_id: roleId };
    apiCall(
      (response) => {
        if (response.data.success) {
          setCompanyInfo(response?.data?.data);
          sessionStorage.setItem("is_trial", response.data.data.is_trial);
           // might require later
          window.pendo.initialize({
            visitor: {
              id: uuId,
              Name: sessionStorage.getItem("user_name") !== "null" ? sessionStorage.getItem("user_name") : "",
              Address: "",
              Email: response.data?.data?.company_details?.email,
              Role: roleId === "5" ? "Owner" : roleId === "6" ? "Admin" : roleId === "7" ? "Inspector" : "",
            },
            account: {
              id: companyId,
              ParentCompany: "PalmTech",
              BillingStartDate: response.data?.data?.pendo?.subscription_start_date
                ? moment(moment.utc(response.data?.data?.pendo?.subscription_start_date).local()).format("YYYY-MM-DD HH:mm:ss")
                : "",
              SubscriptionType: response.data?.data?.pendo?.subscription_type,
              TrialStartDate: response.data?.data?.pendo?.profile_creation_date ? moment(moment.utc(response.data?.data?.pendo?.profile_creation_date).local()).format("YYYY-MM-DD HH:mm:ss") : "",
            },
          });
          window.Intercom('boot', {
            app_id: 'x06n6pzl',
            email: response.data?.data?.company_details?.email,
            name: sessionStorage.getItem("user_name") !== "null" ? sessionStorage.getItem("user_name") : "",
          });
        } else {
          console.log("else", response.data?.message);
        }
      },
      formdata,
      "GET_COMPANY_INFO"
    );
  };

  // PALM 10272 - adding back the ZenDesk snippet

  // useEffect(() => {
  //   // Check if the script with the specified ID already exists
  //   const existingScript = document.getElementById("ze-snippet");

  //   if (!existingScript && !window.location.href.includes("view-agreement")) {
  //     // If the script doesn't exist and the condition is met, create and append the script
  //     const script = document.createElement("script");
  //     script.id = "ze-snippet";
  //     script.src = "https://static.zdassets.com/ekr/snippet.js?key=41c71372-f357-4b14-89fa-edc6b7ed14b4";
  //     document.head.appendChild(script);
  //   }
  // }, []);

  useEffect(() => {
    if (uuId && companyId) getCompanyInfo();
    if (sessionStorage.getItem("timezone") === null && !location?.includes("view-agreement")) {
      axios
        .get("https://ipapi.co/json/?key=EJ1FHhnqyB6gRoAik4SRHnb8peb3wBAapbY93Q7TZkYZ0Tsu5R")
        .then((response) => {
          sessionStorage.setItem("timezone", response?.data?.timezone);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    // eslint-disable-next-line
  }, [reload]);

  return (
    <div>
      <Toaster
        position="bottom-left"
        toastOptions={{
          className: "toastbar",
          icon: null,
          error: { duration: 5000 },
          success: { duration: 5000 },
          style: {
            backgroundColor: "#323232",
            color: "#ffffff",
            minWidth: "434px",
            justifyContent: "space-between",
            display: "flex",
            padding: "14px 10px 14px 10px",
            borderRadius: "4px",
          },
        }}>
        {(t) => (
          <ToastBar toast={t}>
            {({ message }) => (
              <>
                <p className="toast_message">{message}</p>
                <div className="toast_close_icon_display">{t.type !== "loading" && <CloseIcon className="toast_close_icon" onClick={() => toast.dismiss(t.id)} />}</div>
              </>
            )}
          </ToastBar>
        )}
      </Toaster>
      <HashRouter>
        <div className="app_container">
          <div className="main__container">
            <Provider store={store}>
              <AppContext.Provider value={{ companyInfo, setReload, reload }}>
                <Router />
              </AppContext.Provider>
            </Provider>
          </div>
        </div>
      </HashRouter>
    </div>
  );
}
export default App;
