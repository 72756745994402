import React, { useEffect, useState } from "react";
import "./FroalaEditorStyles.scss";
import Froalaeditor from "froala-editor";
import FroalaEditor from "react-froala-wysiwyg";
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/css/froala_style.min.css";
// import "froala-editor/js/plugins/word_paste.min.js";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/js/plugins/code_view.min.js";
import "froala-editor/css/plugins/code_view.min.css";
// import "froala-editor/js/third_party/spell_checker.min.js";
// import "froala-editor/css/third_party/spell_checker.min.css";
import BulbIcon from "../../../assets/images/bulbIcon.png";
// import AICommentHelper from "../../Components/LineDetails/CommentDetails/AICommentHelper";

const FroalaWithoutDropdown = (props) => {
  const {
    categoryName,
    categoryNameFromLocation,
    showAIModal,
    setShowAIModal,
    setCommentText,
    commentTitle,
    showAI,
    editor,
    id,
    onChange,
    editorData,
    onBlur,
    commentData,
    copy,
    froalaConfig,
    toolbarButtonsConfig,
  } = props;
  const [state, setState] = useState({
    open: false,
    newOption: "",
    openHelp: false,
    dropdownList: [],
    textfield: false,
    hoverOption: null,
    editedOption: null,
    dialogOptionList: [],
    selectedDropdown: null,
  });
  const { openHelp } = state;

  useEffect(() => {
    const editorInstance = editor?.current?.editor;
    let editorContent = editorInstance?.html?.get();
    if (editorContent !== editorData?.innerHTML) {
      setTimeout(() => {
        if ((!editorContent || editorContent === undefined || copy) && editorInstance && editorData?.innerHTML) {
          editorInstance?.html?.set(editorData?.innerHTML);
        }
      }, 100);
    }
  }, [editorData]);

  const handleModelChange = (model) => {
    onChange(id, model);
  };

  const openHelpDialog = () => {
    setState((prevState) => ({ ...prevState, openHelp: !openHelp }));
    setShowAIModal(!showAIModal);
  };

  Froalaeditor.DefineIcon("aiHelper", { SRC: BulbIcon, ALT: "Image button", height: 50, width: "50px", template: "image" });
  Froalaeditor.RegisterCommand("aiHelper", {
    title: "AI helper",
    focus: false,
    undo: true,
    refreshAfterCallback: true,
    callback: function () {
      openHelpDialog();
    },
  });

  if (showAI) {
    toolbarButtonsConfig.aiHelper = { buttons: ["aiHelper"], align: "left" };
  }
  let isHandlingImage = false;

  const handleImageBeforeUpload = (images) => {
    if (images.length && !isHandlingImage) {
      isHandlingImage = true;
      const reader = new FileReader();
      reader.onload = (e) => {
        const editorInstance = editor.current.editor;
        const cursorPosition = editorInstance.selection.get();
        if (cursorPosition) editorInstance.image.insert(e.target.result, true);
        isHandlingImage = false;
      };
      reader.readAsDataURL(images[0]);
    }
    return false;
  };

  const handlePasteBefore = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // if (isHandlingImage) return;
    const clipboardData = e.clipboardData || window.clipboardData;
    const items = clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.startsWith("image/")) {
        const file = items[i].getAsFile();
        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            const editorInstance = editor.current.editor;
            editorInstance.image.insert(e.target.result, true, null, null, { width: 'auto' });
          };
          reader.readAsDataURL(file);
        }
        return;
      }
    }
  };

  return (
    <div id={id}>
      <FroalaEditor
        id={id}
        ref={editor}
        value={editorData}
        onModelChange={handleModelChange}
        config={{
          key: "1CC3kA6C7A5A3B3A3E3cWHNGGDTCWHId1Eb1Oc1Yh1b2Ld1POkE3D3F3C7A4A4A4G3H3B1==",
          spellCheck: true,
          imagePaste: true,
          placeholderText: " ",
          charCounterCount: false,
          quickInsertEnabled: false,
          toolbarButtons: toolbarButtonsConfig,
          imageEditButtons: ["imageReplace", "imageAlign", "imageRemove", "imageSize", "imageDisplay", "imageAlt", "imageLink"],
          imageAllowedTypes: ['jpeg', 'jpg', 'png'],
          toolbarButtonsXS:
            id === "agreement-editor"
              ? [
                ["undo", "redo"],
                ["bold", "italic", "underline"],
              ]
              : [],
          events: {
            // "paste.before": id === "agreement-editor" && handlePasteBefore,
            // "image.beforeUpload": handleImageBeforeUpload,
            // "paste.before": handlePasteBefore,
          },
          ...froalaConfig,
        }}
      />
      {/* <AICommentHelper
        categoryName={categoryName}
        categoryNameFromLocation={categoryNameFromLocation}
        showAIModal={showAIModal}
        setShowAIModal={setShowAIModal}
        id={id}
        editor={editor}
        setCommentText={setCommentText}
        commentTitle={commentTitle}
        open={openHelp}
        onClose={openHelpDialog}
        handleSetComment={handleSetComment}
      /> */}
    </div>
  );
};

export default FroalaWithoutDropdown;
