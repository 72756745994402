import moment from "moment";
import { useState } from "react";
import validator from "validator";
import toast from "react-hot-toast";
import { LoadingButton } from "@mui/lab";
import { Userpilot } from "userpilot";
import { apiCall } from "../../utils/action";
import { formatZip } from "../../utils/services";
import logo from "../../../assets/images/logo.png";
import "../../../assets/styles/containers/_login.scss";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate, useLocation, Link } from "react-router-dom";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { Alert, Grid, Paper, TextField, Typography, InputLabel, IconButton, FormControl, useMediaQuery, InputAdornment, FormControlLabel, Checkbox } from "@mui/material";

const CreatePassword = () => {
  // get token and email from signup mail url
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let tokenParam = searchParams.get("token");
  let emailParamEncoded = encodeURIComponent(searchParams.get("email"));
  let emailParam = decodeURIComponent(emailParamEncoded.replace(/%20/g, "+"));

  const [otp, setOtp] = useState("");
  const [agree, setAgree] = useState(0);
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(emailParam);
  const [enable2fa, setEnable2fa] = useState(false);
  const [confirmPass, setConfirmPass] = useState("");
  const matches = useMediaQuery("(min-width:900px)");
  const [emailerror, setemailError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [passworderror, setpasswordError] = useState(false);
  const [passworderrorText, setpasswordErrorText] = useState("");
  const [confirmPassError, setConfirmPassError] = useState(false);
  const [confirmPassErrorText, setConfirmPassErrorText] = useState("");
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  let navigate = useNavigate();
  const routeChange = (roleId) => {
    roleId === 2 || roleId === 3 || roleId === 4 ? navigate(`/company`) : roleId === 10 || roleId === 11 ? navigate(`/import`) : navigate(`/dashboard`);
  };

  const getCompanyInfo = (company_id, user_id, role_id, route, role) => {
    const formdata = {
      company_id: company_id,
      user_id: user_id,
      role_id: role_id,
    };
    apiCall(
      (response) => {
        const { success, data, message } = response.data;
        if (success) {
          sessionStorage.setItem("company_name", data.company_details.name);
          sessionStorage.setItem("is_trial", data.is_trial);
          routeChange(route);
          toast.success(`Logged in as ${role}`);
        } else {
          // console.log("else", message);
        }
      },
      formdata,
      "GET_COMPANY_INFO"
    );
  };

  const createPassword = () => {
    setLoading(true);
    toast.dismiss();
    const formdata = {
      token: tokenParam,
      email,
      password,
      password_confirmation: confirmPass,
      is_agree: agree,
    };
    apiCall(
      (response) => {
        const { message, success } = response.data;
        if (success) {
          toast.success(message);
          setLoading(false);
          loginuser(email, password);
        } else {
          toast.error(message);
          setLoading(false);
        }
      },
      formdata,
      "CREATE_PASSWORD"
    );
  };

  const OTPLogin = () => {
    setLoading(true);
    const formdata = { user_id: sessionStorage.getItem("user_id"), otp: otp };
    apiCall(
      (response) => {
        const { success, data, message } = response.data;
        if (success) {
          sessionStorage.setItem("token", data.token);
          sessionStorage.setItem("user_id", data.user_id);
          sessionStorage.setItem("user_name", data.user_name);
          sessionStorage.setItem("last_name", data.last_name);
          sessionStorage.setItem("first_name", data.first_name);
          sessionStorage.setItem("company_id", data.company_id);
          sessionStorage.setItem("is_from_us", data.is_from_us);
          sessionStorage.setItem("role_id", data.role_data.role_id);
          sessionStorage.setItem("total_insp", data.total_inspections);
          sessionStorage.setItem("is_trial_company", data.is_trial_company);
          sessionStorage.setItem("is_trial", data.is_trial);
          sessionStorage.setItem("can_create_inspection", data.can_create_inspection);
          getCompanyInfo(data.company_id, data.user_id, data.role_data.role_id, data.role_data.role_id, data.role_data.role_display_name);
          setOtp("");
          setLoading(false);
        } else {
          toast.error(message);
          setOtp("");
          setLoading(false);
          // console.log("else", message);
        }
      },
      formdata,
      "OTP_LOGIN"
    );
  };

  const resendOTP = () => {
    const formdata = { user_id: sessionStorage.getItem("user_id") };
    apiCall(
      (response) => {
        const { message } = response.data;
        if (response.data.success) {
          toast.success(message);
          setOtp("");
        } else {
          toast.error(message);
          setOtp("");
          // console.log("else", response.data.message);
        }
      },
      formdata,
      "RESEND_OTP"
    );
  };

  const handleOTPChange = (e) => {
    const formattedOtp = formatZip(e.target.value);
    setOtp(formattedOtp);
  };

  //login user function
  const loginuser = (email, password) => {
    setLoading(true);
    toast.dismiss();
    const formdata = { userEmail: email, password: password };
    apiCall(
      (response) => {
        const { status_code, data, success, message } = response.data;
        if (status_code === 201) {
          sessionStorage.setItem("user_id", data.user_id);
          navigate("/auth");
        }
        if (success && status_code === 200) {
          sessionStorage.setItem("token", data.token);
          sessionStorage.setItem("user_id", data.user_id);
          sessionStorage.setItem("user_name", data.user_name);
          sessionStorage.setItem("last_name", data.last_name);
          sessionStorage.setItem("first_name", data.first_name);
          sessionStorage.setItem("company_id", data.company_id);
          sessionStorage.setItem("is_from_us", data.is_from_us);
          sessionStorage.setItem("role_id", data.role_data.role_id);
          sessionStorage.setItem("total_insp", data.total_inspections);
          sessionStorage.setItem("is_trial_company", data.is_trial_company);
          sessionStorage.setItem("is_trial", data.is_trial);
          sessionStorage.setItem("can_create_inspection", data.can_create_inspection);
          // Userpilot.identify(data.user_id, {
          //   name: data.user_name,
          //   email: email,
          //   created_at: new Date(),
          //   palm_role_id: data.role_data.role_id,
          //   show_user_pilot: data.show_user_pilot,
          // });
          window.pendo.initialize({
            visitor: {
              id: data.user_id,
              Name: data.user_name,
              Address: "",
              Email: email,
              Role: data.role_data.role_id !== null && data.role_data.role_id !== undefined ? (data.role_data.role_id === 5 ? "Owner" : data.role_data.role_id === 6 ? "Admin" : data.role_data.role_id === 7 ? "Inspector" : "") : "",
            },
            account: {
              id: data.company_id,
              ParentCompany: "PalmTech",
              BillingStartDate: data?.pendo?.subscription_start_date ? moment(moment.utc(data?.pendo?.subscription_start_date).local()).format("YYYY-MM-DD HH:mm:ss") : "",
              SubscriptionType: data?.pendo?.subscription_type,
              TrialStartDate: data?.pendo?.profile_creation_date ? moment(moment.utc(data?.pendo?.profile_creation_date).local()).format("YYYY-MM-DD HH:mm:ss") : "",
            },
          });
          getCompanyInfo(data.company_id, data.user_id, data.role_data.role_id, data.role_data.role_id, data.role_data.role_display_name);
        } else {
          // console.log("else", message);
          toast.error(message);
          setLoading(false);
        }
      },
      formdata,
      "LOGIN"
    );
  };

  // validation for email and password********************************************/
  const validation = () => {
    let checkboxSpan = document.getElementById("agreement-box-error");
    checkboxSpan.innerHTML = "";
    if (email === "" || email === null) {
      setemailError(true);
    } else if (validator.isEmail(email)) {
      setemailError(false);
    } else {
      setemailError(true);
    }
    if (password === "") {
      setpasswordError(true);
      setpasswordErrorText("Password is required");
    } else if (!validator.isStrongPassword(password)) {
      setpasswordError(true);
      setpasswordErrorText("Error: Please make your password stronger. It must be at least 8 characters long, contain at least one special character, a number and have both uppercase and lowercase letters.");
    } else {
      setpasswordError(false);
    }
    if (confirmPass === "") {
      setConfirmPassError(true);
      setConfirmPassErrorText("Password confirmation is required");
    }
    if (password !== "" && validator.isStrongPassword(password) && confirmPass !== "") {
      if (password !== confirmPass) {
        setpasswordErrorText("Passwords must match");
        setConfirmPassErrorText("Passwords must match");
        setpasswordError(true);
        setConfirmPassError(true);
      } else {
        setpasswordError(false);
        setConfirmPassError(false);
      }
    }

    if (validator.isEmail(email) && validator.isStrongPassword(password) && password === confirmPass && agree === 0) checkboxSpan.innerHTML = "Please check the terms and conditions";
    if (email !== "" && email !== null && validator.isEmail(email) && password !== "" && validator.isStrongPassword(password) && confirmPass !== "" && password === confirmPass && agree === 1) createPassword();
  };
  // return function
  return (
    <>
      {sessionStorage.getItem("token") ? (
        navigate("/dashboard")
      ) : (
        <>
          {/* background div for mobile and desktop******************************** */}
          <div className={`${matches ? "background" : "background-mobile"}`}>
            <Grid container direction="column" alignItems="center" justifyContent="center">
              {/* palm tech logo*************************************************** */}
              <img className={`${matches ? "logo" : "logo-mobile"}`} src={logo} alt="logo"></img>
              {/* login component starts from here********************************* */}
              <Grid item xs={3}>
                <Paper
                  className="paper-normal"
                  sx={{
                    minWidth: { xs: 370, md: 390, lg: 390 },
                    minHeight: { xs: 505, md: 505, lg: 505 },
                    boxShadow: "0 30px 30px 0 rgba(40, 40, 40, 0.4)",
                  }}
                >
                  <Grid className="center">
                    <Typography
                      className="header"
                      sx={{
                        mt: "27px",
                        fontSize: { xs: "24px", md: "22px", lg: "22px" },
                      }}
                    >
                      Finish creating your account
                    </Typography>
                  </Grid>
                  {/* Input field for email******************************************************************************************************** */}
                  <Grid className="center">
                    <FormControl variant="standard">
                      <InputLabel
                        htmlFor="component-simple"
                        className="input_lable"
                        sx={{
                          top: { xs: "18px", md: "17px", lg: "17px" },
                          fontSize: { xs: "14px", md: "12px", lg: "12px" },
                        }}
                      >
                        EMAIL ADDRESS
                      </InputLabel>
                      <TextField
                        disabled
                        type="email"
                        className="input_field"
                        variant="outlined"
                        error={emailerror}
                        placeholder="Enter email"
                        size={`${matches ? "small" : "medium"}`}
                        onChange={(event) => setEmail(event.target.value)}
                        value={email}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") validation();
                        }}
                        helperText={`${emailerror === true ? "Email is required" : ""}`}
                        sx={{
                          marginTop: "63px",
                          width: { xs: "340px", md: "317px", lg: "317px" },
                        }}
                        InputProps={{
                          className: "input_props_field",
                          sx: {
                            height: { xs: "55px", md: "40px", lg: "40px" },
                            fontSize: { xs: "18px", md: "14px", lg: "14px" },
                            background: "rgba(239, 239, 239, 0.8)",
                            color: "text.primary !important",
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>
                  {/* input field for password *************************************************************************************************** */}
                  <Grid className="center">
                    <FormControl variant="standard">
                      <InputLabel
                        htmlFor="component-simple"
                        className="input_lable"
                        sx={{
                          top: "4px",
                          fontSize: { xs: "14px", md: "12px", lg: "12px" },
                        }}
                      >
                        CREATE A PASSWORD
                      </InputLabel>
                      <TextField
                        className="input_field"
                        variant="outlined"
                        id="password"
                        error={passworderror}
                        placeholder="Password"
                        size={`${matches ? "small" : "medium"}`}
                        type={showPassword ? "text" : "password"}
                        onChange={(event) => setPassword(event.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") validation();
                        }}
                        helperText={passworderror ? passworderrorText : "min 8 characters with uppercase, lowercase, a number and a special character"}
                        sx={{
                          marginTop: "47px",
                          width: { xs: "340px", md: "317px", lg: "317px" },
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton sx={{ mr: "-9px" }} onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          ),
                          className: "input_props_field",
                          sx: {
                            height: { xs: "55px", md: "40px", lg: "40px" },
                            fontSize: { xs: "18px", md: "14px", lg: "14px" },
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid className="center">
                    <FormControl variant="standard">
                      <InputLabel
                        htmlFor="component-simple"
                        className="input_lable"
                        sx={{
                          top: "4px",
                          fontSize: { xs: "14px", md: "12px", lg: "12px" },
                        }}
                      >
                        RE-TYPE A PASSWORD
                      </InputLabel>
                      <TextField
                        className="input_field"
                        variant="outlined"
                        id="confirm_password"
                        error={confirmPassError}
                        placeholder="Password"
                        size={`${matches ? "small" : "medium"}`}
                        type={showPassword1 ? "text" : "password"}
                        onChange={(event) => setConfirmPass(event.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") validation();
                        }}
                        helperText={confirmPassError && confirmPassErrorText}
                        sx={{
                          marginTop: "47px",
                          width: { xs: "340px", md: "317px", lg: "317px" },
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton sx={{ mr: "-9px" }} onClick={() => setShowPassword(!showPassword1)} onMouseDown={() => setShowPassword1(!showPassword1)}>
                                {showPassword1 ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          ),
                          className: "input_props_field",
                          sx: {
                            height: { xs: "55px", md: "40px", lg: "40px" },
                            fontSize: { xs: "18px", md: "14px", lg: "14px" },
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>
                  {/* terms and conditions********************************************* */}
                  <Grid className="center_terms_and_condition">
                    <FormControlLabel
                      control={<Checkbox onChange={(e) => setAgree(e.target.checked ? 1 : 0)} id="agree_terms" />}
                      label={
                        <div className="terms_and_condition">
                          I agree to the{" "}
                          <a className="terms_and_condition_hyperlink" href="https://www.palmtech.com/terms" rel="noreferrer" target="_blank">
                            Terms of use
                          </a>{" "}
                          and{" "}
                          <a className="terms_and_condition_hyperlink" href="https://www.palm-tech.com/privacy" rel="noreferrer" target="_blank">
                            Privacy Policy.
                          </a>
                        </div>
                      }
                      labelPlacement="end"
                    />
                    <div id="agreement-box-error"></div>
                  </Grid>
                  {/* login in button ************************************************* */}
                  <Grid className="center">
                    <LoadingButton
                      className="login_button"
                      sx={{
                        height: { xs: 42, md: 34, lg: 34 },
                        width: { xs: 180, md: 119, lg: 119 },
                        fontSize: { xs: 16, md: 13, lg: 13 },
                      }}
                      size="small"
                      variant="contained"
                      loading={loading}
                      onClick={() => validation()}
                    >
                      Log in
                    </LoadingButton>
                  </Grid>
                  {/* Sign up hyperlink *********************************************** */}
                  <Grid className="center" sx={{ mt: 2 }}>
                    <Typography
                      className="link_nologin"
                      fontSize={{
                        xs: "16px",
                        md: "12px",
                        lg: "12px",
                        xl: "12px",
                      }}
                    >
                      Already have an account?&nbsp;
                    </Typography>
                    <Link to="/">
                      <Typography
                        className="link_signup"
                        fontSize={{
                          xs: "16px",
                          md: "12px",
                          lg: "12px",
                          xl: "12px",
                        }}
                      >
                        Log in here
                      </Typography>
                    </Link>
                  </Grid>
                </Paper>
                {/* Footer starts from here****************************************************************************************************************** */}
                <Grid
                  className="center"
                  sx={{
                    marginTop: { xs: "123px", md: "41px", lg: "41px" },
                    marginBottom: { xs: "0px", md: "25px", lg: "25px" },
                  }}
                >
                  <div className="footer">
                    <p>© 2023 Palmtech&nbsp;</p>
                    <a href="https://www.palm-tech.com/" rel="noreferrer" target="_blank" style={{ marginBottom: "-10px" }} className="footer">
                      www.palm-tech.com
                    </a>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </>
  );
};
export default CreatePassword;