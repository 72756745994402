import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import toast from "react-hot-toast";
import StepButton from "@mui/material/StepButton";
import Typography from "@mui/material/Typography";
import { Grid, StepLabel } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Userpilot } from "userpilot";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import validator from "validator";
// component imports
import BasicDetails from "../../components/ScheduleInspection/BasicDetails";
import PropertyDetails from "../../components/ScheduleInspection/PropertyDetails";
import AgentDetails from "../../components/ScheduleInspection/AgentDetails";
import ClientDetails from "../../components/ScheduleInspection/ClientDetails";
import Services from "../../components/ScheduleInspection/Services";
import Preloader from "../../helpers/Preloader";
import { apiCall } from "../../utils/action";
//custom style import
import {
  AddNewClientRequest,
  ScheduleNewInspection,
  AddNewAgentRequest,
  EditInspectionAPI,
  RealEstateAPI,
} from "../../pages/scheduleInspection/action";
import ScheduleMessage from "../../common/schedule/Schedule";
import ScheduleButtons from "../../common/schedule/ScheduleButtons";
import {
  formatPhoneNumber,
  formatPhoneNumberDashes,
} from "../../utils/services";
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import htmlToPdfmake from "html-to-pdfmake";
import moment from "moment";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const ScheduleLayout = (props) => {
  const {
    inspList,
    navigate,
    clientList,
    agentsLists,
    setEditRefresh,
    servicesLists,
    uuId,
    inspectionId,
    location,
    companyId,
    roleId,
    getDetailsFetch,
    ScheduleInspectionSteps,
    isMobile,
    isTaborMobile,
    queryId,
    Type,
    editValues,
    agreementsList,
    allTemplateList,
    isagreementEnabled,
  } = props;
  let agreementIdPDF = agreementsList.map((e) => e.id);
  const [activeStep, setActiveStep] = useState(0);
  const [address, setAddress] = React.useState({
    location_address: "",
    location_address_2: "",
    location_city: "",
    location_state: "",
    location_zip: "",
  });

  const [dateTime, setDateTime] = React.useState();
  const [clientName, setClientName] = useState([]);
  const [clientName2, setClientName2] = useState([]);

  //usestate for adding seller & buyer agent (anil)
  const [sellerAgent, setSellerAgent] = useState([]);
  const [sellerAgent2, setSellerAgent2] = useState([]);
  const [buyerAgent, setBuyerAgent] = useState([]);
  const [buyerAgent2, setBuyerAgent2] = useState([]);

  const [next, setNext] = useState();
  //ending
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [status, setStatus] = useState(0);
  const [isNewAgent, setIsNewAgent] = useState(false);
  const [isNewClient, setIsNewClient] = useState(false);
  const [isNewClient2, setIsNewClient2] = useState(false);
  const [selectedInspectors, setSelectedInspectors] = useState([]);

  // validations
  const [addressValid, setAddressValid] = useState(null);
  const [cityValid, setCityValid] = useState(null);
  const [stateValid, setStateValid] = useState(null);
  const [zipValid, setZipValid] = useState(null);
  const [clientValid, setClientValid] = useState(0);
  const [servicesValid, setServicesValid] = useState(2);
  const [templateValid, setTemplateValid] = useState(2);
  const [agreeValid, setAgreeValid] = useState(2);

  const [disableSche, setDisableSche] = useState(false);
  const [disableSchedule, setDisableSchedule] = useState(false);
  const [disableSchedule2, setDisableSchedule2] = useState(false);
  const [disableSchedule3, setDisableSchedule3] = useState(false);
  const [ChargeValid, setChargeValid] = useState(false);
  const [isSchedule, setIsSchedule] = useState(false);
  // client
  const [clientFName, setClientFName] = useState(null);
  const [clientEmail, setClientEmail] = useState(null);
  const [clientLName, setClientLName] = useState(null);
  const [clientPhone, setClientPhone] = useState(null);

  const [clientFName2, setClientFName2] = useState(null);
  const [clientEmail2, setClientEmail2] = useState(null);
  const [clientLName2, setClientLName2] = useState(null);
  const [clientPhone2, setClientPhone2] = useState(null);

  const [clientFName3, setClientFName3] = useState(null);
  const [clientEmail3, setClientEmail3] = useState(null);
  const [clientLName3, setClientLName3] = useState(null);

  const [inputValue, setInputValue] = useState("");
  const [inputValue2, setInputValue2] = useState("");
  const [ordercharge, setOrderCharge] = useState([]);
  const [orderdiscounts, setOrderDiscounts] = useState([]);
  const [sch, setSch] = useState(false);
  const [showTooltip, setShowTooltip] = React.useState(false);

  const [showCustomAddInput, setShowCustomAddInput] = useState(false);
  const [showDiscountInput, setShowDiscountInput] = useState(false);
  const [dataIsCorrect, setDataIsCorrect] = useState(false);
  const [errors, setErrors] = useState({});

  let currentDate = new Date();

  // given url string

  const [user, setUser] = React.useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
  });

  const [user2, setUser2] = React.useState({
    first_name2: "",
    last_name2: "",
    phone2: "",
    email2: "",
  });

  const IsCustomCharges = (status) => {
    if (status) {
      setChargeValid(true);
    } else {
      setChargeValid(false);
    }
  };
  //  const agentArray=[]

  // variables for unsigned agreement upload
  let convertedPDFArray = [];
  let agreementNameArray = [];

  const [showLoader, setShowLoader] = useState(false);
  // snackbar for subcription message show
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [agreementChangeValue, setAgreementChangeValue] = useState([]);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleInput = (e) => {
    // this is where we'll call our future formatPhoneNumber function that we haven't written yet.
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    // we'll set the input value using our setInputValue
    setInputValue(formattedPhoneNumber);
  };

  const handleInput2 = (e) => {
    // this is where we'll call our future formatPhoneNumber function that we haven't written yet.
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    // we'll set the input value using our setInputValue
    setInputValue2(formattedPhoneNumber);
  };

  const handleUser = (evt) => {
    const value =
      evt.target.name === "phone"
        ? formatPhoneNumberDashes(evt.target.value)
        : evt.target.value.trimStart();
    setUser({
      ...user,
      [evt.target.name]: value,
    });
  };

  const handleUser2 = (evt) => {
    const value =
      evt.target.name === "phone2"
        ? formatPhoneNumberDashes(evt.target.value)
        : evt.target.value.trimStart();
    setUser2({
      ...user2,
      [evt.target.name]: value,
    });
  };

  var fname = [];
  var lname = [];
  var emai = [];
  var ph = [];

  fname.push(user.first_name);
  lname.push(user.last_name);
  emai.push(user.email);
  ph.push(user.phone);

  const formdata = {
    company_id: companyId,
    user_id: uuId,
    role_id: roleId,
    first_name: fname,
    last_name: lname,
    phone: ph,
    email: emai,
    // company : ''
  };

  var fname2 = [];
  var lname2 = [];
  var emai2 = [];
  var ph2 = [];

  fname2.push(user2.first_name2);
  lname2.push(user2.last_name2);
  emai2.push(user2.email2);
  ph2.push(user2.phone2);

  const formdata2 = {
    company_id: companyId,
    user_id: uuId,
    role_id: roleId,
    first_name: fname2,
    last_name: lname2,
    phone: ph2,
    email: emai2,
    // company : ''
  };

  const [inputValue3, setInputValue3] = useState("");

  const [user3, setUser3] = React.useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    company: "",
  });
  const handleOrders = (charges, discounts) => {
    setOrderCharge(charges);
    setOrderDiscounts(discounts);
  };

  const handleUser3 = (evt) => {
    const value =
      evt.target.name === "phone"
        ? formatPhoneNumberDashes(evt.target.value)
        : evt.target.value.trimStart();
    setUser3({
      ...user3,
      [evt.target.name]: value,
    });
  };

  const handleInput3 = (e) => {
    // this is where we'll call our future formatPhoneNumber function that we haven't written yet.
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    // we'll set the input value using our setInputValue
    setInputValue3(formattedPhoneNumber);
  };

  var fname3 = [];
  var lname3 = [];
  var emai3 = [];
  var ph3 = [];
  let agency = [];

  fname3.push(user3.first_name);
  lname3.push(user3.last_name);
  emai3.push(user3.email);
  ph3.push(user3.phone);
  agency.push(user3.company);

  const formdata3 = {
    company_id: companyId,
    user_id: uuId,
    role_id: roleId,
    first_name: fname3,
    last_name: lname3,
    phone: ph3,
    email: emai3,
    agency,
  };

  const InspectorChange = (val) => {
    setSelectedInspectors(val);
  };

  const getDateTime = (timestramp) => {
    setDateTime(timestramp);
  };

  const TemplateChange = (val) => {
    setSelectedTemplate(val);  // Directly set the selectedTemplate without checking for Texas conditions
  };
  

  const handleSellerAgent = (val) => {
    setSellerAgent(val);
  };
  const handleSellerAgent2 = (val) => {
    setSellerAgent2(val);
  };

  const handleChangeAgent = (val) => {
    setBuyerAgent2(val);
  };

  const handleBuyerAgent = (val) => {
    setBuyerAgent(val);
  };

  const handleChangeClient = (val) => {
    setClientName(val);
  };

  const handleChangeClient2 = (val) => {
    setClientName2(val);
  };

  const handleNewClient = () => {
    setClientFName(undefined);
    setClientLName(undefined);
    setClientEmail(undefined);
    setClientPhone(undefined);
    setClientFName2(undefined);
    setClientLName2(undefined);
    setClientEmail2(undefined);
    setClientPhone2(undefined);
    setIsNewClient(!isNewClient);
  };

  const handleNewClient2 = () => {
    setClientFName(undefined);
    setClientLName(undefined);
    setClientEmail(undefined);
    setClientPhone(undefined);
    setClientFName2(undefined);
    setClientLName2(undefined);
    setClientEmail2(undefined);
    setClientPhone2(undefined);
    setIsNewClient2(!isNewClient2);
  };

  const handleNewAgent = () => {
    // clear the when click on the cancel button(anil)
    setUser3({
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      company: "",
    });
    setIsNewAgent(!isNewAgent);
  };

  var Inspectors = [];
  selectedInspectors.length > 0 &&
    selectedInspectors.map((ins) => Inspectors.push(ins.id));

  // filterinspector (anil)
  let filterinspector = inspList.filter((ins) => !Inspectors.includes(ins.id));

  // var agentIds = [];
  // agentName.length > 0 && agentName.map((agent) => agentIds.push(agent.id));

  //adding of the buyeragent (anil)
  var buyerAgentId = [];
  // uncomment
  var buyerArr = buyerAgent.concat(buyerAgent2);
  buyerArr.length > 0 && buyerArr.map((agent) => buyerAgentId.push(agent.id));

  //adding of the selleragent(anil)
  let sellerAgentIds = [];

  // uncomment
  var sellerArr = sellerAgent.concat(sellerAgent2);
  sellerArr.length > 0 &&
    sellerArr.map((agent) => sellerAgentIds.push(agent.id));

  //adding of the buyer & seller(anil)
  let buyer_seller_id = [...buyerAgentId, ...sellerAgentIds];
  let filteragents = agentsLists.filter(
    (item) => !buyer_seller_id.includes(item.id)
  );

  var clientIds = [];
  clientName.length > 0 &&
    clientName.map((client) => clientIds.push(client.id));
  clientName2.length > 0 &&
    clientName2.map((client) => clientIds.push(client.id));

  var services = [];
  selectedServices.length > 0 &&
    selectedServices.map((ser) => services.push(ser.id));

  var templateId = [];
  selectedTemplate.length > 0 &&
    selectedTemplate?.map((temp) => templateId.push(temp.id));
  var clientIdList = [];
  clientList?.map(
    (client) =>
      clientName.length > 0 &&
      clientName.map((id) => {
        return client.name === id && clientIdList.push(client.id);
      })
  );
  clientList?.map(
    (client) =>
      clientName2.length > 0 &&
      clientName2.map((id) => {
        return client.name === id && clientIdList.push(client.id);
      })
  );

  const handleAddress = (evt) => {
    const value = evt.target.value;

    setAddress({
      ...address,
      [evt.target.name]: value,
    });
  };

  const handleTotalPrice = (price) => {
    setTotalPrice(price);
  };

  var templateList = [];
  const template =
    selectedServices?.length > 0
      ? selectedServices.map(
        (services) =>
          services.template.length > 0 &&
          services.template.map((template) =>
            templateList.push({
              services_id: template?.service_id,
              template_id: template?.template_id,
              title: template?.template?.Name,
            })
          )
      )
      : [];

  let selectedAgreementList = [];

  selectedServices.forEach((value) =>
    agreementIdPDF?.includes(value.agreementid)
      ? selectedAgreementList.push(value.agreementid)
      : ""
  );
  let selectedAgreementListValue = [];
  agreementsList.forEach((value) =>
    selectedAgreementList.includes(value.id)
      ? selectedAgreementListValue.push({ id: value.id, title: value.title })
      : ""
  );
  // let selectedAgreementsIDArray = selectedAgreementListValue.map((i) => i.id);

  const ServicesChange = (val) => {
    const resultArray = selectedServices.filter(
      (alreadySelectedService) =>
        !val.some(
          (newlySelectedService) =>
            newlySelectedService["id"] === alreadySelectedService["id"]
        )
    );
    // if (val?.includes(val?.find((el) => el?.template[0]?.template?.is_texas === 1)) && selectedTemplate?.includes(selectedTemplate?.find((el) => el.is_texas === 0))) {
    //   return toast.error("Texas Template cannot be combined with other templates");
    // } else if (val?.includes(val?.find((el) => el?.template[0]?.template?.is_texas === 0)) && selectedTemplate?.includes(selectedTemplate?.find((el) => el.is_texas === 1))) {
    //   return toast.error("Texas Template cannot be combined with other templates");
    // }
    setSelectedServices(val);
    setAgreementChangeValue((prev) => {
      let selectedAgreementListForAgrmnt = [];
      val.forEach((value) =>
        agreementIdPDF?.includes(value.agreementid)
          ? selectedAgreementListForAgrmnt.push(value.agreementid)
          : ""
      );
      let selectedAgreementListValueForAgrmnt = [];
      agreementsList.forEach((value) =>
        selectedAgreementListForAgrmnt.includes(value.id)
          ? selectedAgreementListValueForAgrmnt.push({
            id: value.id,
            title: value.title,
          })
          : ""
      );
      return [...selectedAgreementListValueForAgrmnt];
    });
    // autoselect template on service selection

    let newTemplateList = [...selectedTemplate];
    val.forEach((selectedServiceOnChange) => {
      if (selectedServiceOnChange?.template?.length > 0) {
        const templateList = selectedServiceOnChange?.template.map(
          (obj) => obj.template
        );
        let newlyFilteredTemplates;
        if (resultArray?.length > 0) {
          newlyFilteredTemplates = resultArray?.template.map((obj) => obj.template);
          newTemplateList = selectedTemplate.filter(
            (obj1) => !newlyFilteredTemplates.some((obj2) => obj2["id"] === obj1["id"])
          );
        }
        //check texas templates already selected
        const is_texas_selected = selectedTemplate?.findIndex(
          (el) => el.is_texas === 1
        );
        if (newTemplateList.length === 0) {
          newTemplateList = [...templateList];
        } else if (is_texas_selected === -1) {
          //no texas found
          const is_texas_new = templateList?.findIndex((el) => el.is_texas === 1);
          if (is_texas_new === -1) {
            newTemplateList = [...newTemplateList, ...templateList];
          }
        }
      }
    });
    let tempArray = [];
    newTemplateList.map((obj) => {
      const index = tempArray.findIndex((el) => el.id === obj.id);
      if (index === -1) {
        tempArray.push(obj);
      }
    });
    setSelectedTemplate(tempArray);
  };

  const agreementChange = (value) => {
    setAgreementChangeValue(value);
  };
  var minutes = [];
  const AddMinutes = selectedServices.map((services) =>
    minutes.push(services.minutes)
  );

  useEffect(() => {
    if (
      Type === "schedule" &&
      filterinspector.length === 1 &&
      selectedInspectors.length === 0
    ) {
      setSelectedInspectors(filterinspector);
    }
  }, [
    Type === "schedule" &&
    filterinspector?.length === 1 &&
    selectedInspectors?.length === 0,
  ]);

  useEffect(() => {
    if (
      Type === "schedule" &&
      servicesLists.length === 1 &&
      selectedServices.length === 0 &&
      activeStep === 4
    ) {
      ServicesChange(servicesLists);
    }
  }, [
    Type === "schedule" &&
    servicesLists.length === 1 &&
    selectedServices.length === 0 &&
    activeStep === 4,
  ]);

  useEffect(() => {
    if (Type === "edit" && servicesLists) {
      setEditRefresh(props.editRefresh + 1);
    }
  }, [servicesLists.length > 0]);
  const {
    location_address,
    location_address_2,
    location_city,
    location_state,
    location_zip,
  } = address;
  useEffect(() => {
    if (Type === "edit") {
      let editInsp = [];
      let editClient = [];
      let editAgent = [];
      let editService = [];
      let editTemplate = [];

      editValues.orderinspector &&
        editValues.orderinspector.length > 0 &&
        editValues.orderinspector.map(
          (insp) => (
            editInsp.push({
              id: insp.inspector_id,
              name: insp.inspector.last_name + " " + insp.inspector.first_name,
            }),
            setSelectedInspectors(editInsp)
          )
        );
      setAgreementChangeValue((prev) => {
        let savedUnsignedAgreementArray = [
          ...editValues?.agreements?.unsigned_agreements,
        ];
        let unsignedModified =
          savedUnsignedAgreementArray.length !== 0
            ? savedUnsignedAgreementArray.map((agrmnt) => {
              return {
                id: agrmnt?.agreementdetails?.id,
                title: agrmnt?.agreementdetails?.title,
              };
            })
            : [];
        return unsignedModified;
      });
      setAddress({
        // ...address,
        location_address: editValues.location_address,
        location_address_2: editValues.location_address_2,
        location_city: editValues.location_city,
        location_state: editValues.location_state,
        location_zip: editValues.location_zip,
      });

      if (
        editValues.location_address === null ||
        (editValues.location_address_2 === null &&
          editValues.location_city === null &&
          editValues.location_state === null &&
          editValues.location_zip === null)
      ) {
        setDisableSchedule(false);
      } else {
        setDisableSchedule(true);
      }

      editValues.ordertemplate &&
        editValues.ordertemplate.length > 0 &&
        editValues.ordertemplate.map(
          (temp) => (
            editTemplate.push({
              id: temp.template.id,
              name: temp.template.name,
              is_texas: temp.template.is_texas,
              is_pdf_template: temp.template.is_pdf_template,
            }),
            setSelectedTemplate(editTemplate)
          )
        );

      editValues.orderclient &&
        editValues.orderclient.length > 0 &&
        editValues.orderclient.map(
          (clin) => (
            editClient.push({
              id: clin.client_id,
              name: clin.client?.last_name + " " + clin.client?.first_name,
            }),
            setClientName(editClient)
          )
        );

      // buyer&seller agent dropdown(anil)
      // agentType=1  :buyer agent
      // agentType=2  :seller agent
      let agentType1 =
        // editValues.orderagent &&
        // editValues.orderagent.length > 0 &&
        editValues?.orderagent?.filter((agent) => agent.agent_type === 1);
      let agentType2 =
        // editValues.orderagent &&
        // editValues.orderagent.length > 0 &&
        editValues?.orderagent?.filter((agent) => agent.agent_type === 2);
      if (agentType1.length !== 0) {
        if (agentType1.length === 1) {
          setBuyerAgent([
            {
              id: agentType1[0]?.agent?.id,
              name:
                agentType1[0]?.agent?.last_name +
                " " +
                agentType1[0]?.agent?.first_name,
            },
          ]);
        } else {
          setBuyerAgent([
            {
              id: agentType1[0]?.agent?.id,
              name:
                agentType1[0]?.agent?.last_name +
                " " +
                agentType1[0]?.agent?.first_name,
            },
          ]);
          setBuyerAgent2([
            {
              id: agentType1[1]?.agent?.id,
              name:
                agentType1[1]?.agent?.last_name +
                " " +
                agentType1[1]?.agent?.first_name,
            },
          ]);
        }
      }
      // else {
      //   setBuyerAgent(agentType1);
      // }
      if (agentType2.length !== 0) {
        if (agentType2.length === 1) {
          setSellerAgent([
            {
              id: agentType2[0]?.agent?.id,
              name:
                agentType2[0]?.agent?.last_name +
                " " +
                agentType2[0]?.agent?.first_name,
            },
          ]);
        } else {
          setSellerAgent([
            {
              id: agentType2[0]?.agent?.id,
              name:
                agentType2[0]?.agent?.last_name +
                " " +
                agentType2[0]?.agent?.first_name,
            },
          ]);

          setSellerAgent2([
            {
              id: agentType2[1]?.agent?.id,
              name:
                agentType2[1]?.agent?.last_name +
                " " +
                agentType2[1]?.agent?.first_name,
            },
          ]);
        }
      }
      // else {
      //   setSellerAgent(agentType2);
      // }

      //---------------------- buyer&seller agent dropdown ending(anil)----------------

      // editValues.orderagent && editValues.orderagent.length > 0 && editValues.orderagent.map((age) => (
      //   editAgent.push({id : age.agent_id, name : age.agent.last_name + ' ' + age.agent.first_name}),
      //   setBuyerAgent(editAgent),
      //   setBuyerAgent2(editAgent),
      //   setSellerAgent(editAgent),
      //   setSellerAgent2(editAgent)
      //   )
      //   )

      editValues.orderservice &&
        editValues.orderservice.length > 0 &&
        editValues.orderservice.map((ser) =>
          servicesLists.map((ser1) => {
            if (ser.service.id === ser1.id) {
              editService.push({
                agreementid: ser1.agreementid,
                id: ser1.id,
                minutes: ser1.minutes,
                rate: ser.service?.amount,
                template: ser1.template,
                title: ser1.title,
              });
              setSelectedServices(editService);
            }
          })
        );
    }
  }, [props.editRefresh]);

  const HandleClose = () => {
    setUser({
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
    });
    handleNewClient();
  };

  const HandleClose2 = () => {
    setUser2({
      first_name2: "",
      last_name2: "",
      phone2: "",
      email2: "",
    });
    handleNewClient2();
  };

  const validate = (activeStep) => {
    if (activeStep === 0) {
      if (selectedInspectors.length === 0) {
        setDisableSche(false);
        setActiveStep(activeStep + 1);
      } else {
        setDisableSche(true);
        setActiveStep(activeStep + 1);
      }
    }

    if (showTooltip) {
      if (document.getElementById("PropertyAddressId").value === "") {
        setAddressValid("Address cannot be blank");
      } else {
        setAddressValid(undefined);
      }
      if (document.getElementById("PropertyCityId").value === "") {
        setCityValid("city cannot be blank");
      } else {
        setCityValid(undefined);
      }
      if (document.getElementById("PropertyStateId").value === "") {
        setStateValid("state cannot be blank");
      } else {
        setStateValid(undefined);
      }

      if (document.getElementById("PropertyZipId").value === "") {
        setZipValid("zip cannot be blank");
      } else {
        setZipValid(undefined);
      }
    }

    if (activeStep === 1) {
      if (
        location_address === "" &&
        location_city === "" &&
        location_state === "" &&
        location_zip === ""
      ) {
        setActiveStep(activeStep + 1);

        setDisableSchedule(false);
      } else if (
        location_address === null &&
        location_city === null &&
        location_state === null &&
        location_zip === null
      ) {
        setActiveStep(activeStep + 1);
        setDisableSchedule(false);
      } else if (
        !(
          location_address !== "" &&
          location_city !== "" &&
          location_state !== "" &&
          location_zip !== ""
        )
      ) {
        setActiveStep(activeStep + 1);
        setDisableSchedule(false);
      } else {
        setActiveStep(activeStep + 1);
        setDisableSchedule(true);
      }
    }

    // if(address){

    if (activeStep === 2 && !isNewClient) {
      setActiveStep(activeStep + 1);
    }

    if (activeStep === 2 && !isNewClient) {
      if (clientName.length > 0 || clientName2.length > 0) {
        // setClientValid(1);
        setDisableSchedule2(true);
        setActiveStep(activeStep + 1);
      } else {
        // setClientValid(0);
        setActiveStep(activeStep);
        setDisableSchedule2(false);
        setActiveStep(activeStep + 1);
      }
    }

    if (activeStep === 2 && isNewClient) {
      if (document.getElementById("clientFirstId").value === "") {
        setClientFName("Name cannot be blank");
      } else {
        setClientFName(undefined);
      }
      if (document.getElementById("clientEmailId").value === "") {
        setClientEmail("Email cannot be blank");
      }
      // email validation (anil)
      else if (
        !validator.isEmail(document.getElementById("clientEmailId").value)
      ) {
        setClientEmail("Please enter a valid email");
      } else if (
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          document.getElementById("clientEmailId").value
        )
      ) {
        setClientEmail("Please enter a valid email");
      } else {
        setClientEmail(undefined);
      }
      if (document.getElementById("clientLastId").value === "") {
        setClientLName("Last name cannot be blank");
      } else {
        setClientLName(undefined);
      }
      if (document.getElementById("clientPhoneId").value === "") {
        setClientPhone("Phone number cannot be blank");
      } else if (
        !/^(?:\+91|0|1)?[ -]?(?:(?:\()?[0-9]{3}(?:\))?[ -]?[0-9]{3}[ -]?[0-9]{4}|[0-9]{10}|(?:[24-9][0-9]{2}|3[01][0-9]{2}|5[56][0-9]{2}|6[0-9]{3})[ -]?[0-9]{4}|(?:8(?:00|55|66|77|88))[ -]?[0-9]{3}[ -]?[0-9]{4})$/.test(
          document.getElementById("clientPhoneId").value
        )
      ) {
        setClientPhone("Please enter a valid phone number");
      } else {
        setClientPhone(undefined);
      }
      // email validation (anil)
      if (
        !(
          document.getElementById("clientFirstId").value === "" ||
          document.getElementById("clientEmailId").value === "" ||
          !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            document.getElementById("clientEmailId").value
          ) ||
          !validator.isEmail(document.getElementById("clientEmailId").value) ||
          document.getElementById("clientPhoneId").value === "" ||
          document.getElementById("clientLastId").value === ""
        ) &&
        /^(?:\+91|0|1)?[ -]?(?:(?:\()?[0-9]{3}(?:\))?[ -]?[0-9]{3}[ -]?[0-9]{4}|[0-9]{10}|(?:[24-9][0-9]{2}|3[01][0-9]{2}|5[56][0-9]{2}|6[0-9]{3})[ -]?[0-9]{4}|(?:8(?:00|55|66|77|88))[ -]?[0-9]{3}[ -]?[0-9]{4})$/.test(
          document.getElementById("clientPhoneId").value
        )
      ) {
        AddNewClientRequest((response) => {
          // const { data, message, success } = response.data;
          if (response?.data?.success) {
            toast.success(response?.data?.message);
            getDetailsFetch();
            if (response?.data?.data) {
              const ci = response?.data?.data[0].client_id;
              const cn = response?.data?.data[0].client_name;
              const total = { id: ci, name: cn };
              clientName.push(total);
              HandleClose();
              HandleClose2();
            }
            if (isNewClient && !isNewClient2) {
              // setActiveStep(activeStep + 1);
              setIsNewClient(false);
            } else {
              setActiveStep(activeStep);
            }
          } else {
            toast.error(response?.data?.message);
          }
        }, formdata);
      }
    }

    if (activeStep === 2 && isNewClient2) {
      if (document.getElementById("clientFirstId2").value === "") {
        setClientFName2("Name cannot be blank");
      } else {
        setClientFName2(undefined);
      }
      if (document.getElementById("clientEmailId2").value === "") {
        setClientEmail2("Email cannot be blank");
      } else if (
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          document.getElementById("clientEmailId2").value
        )
      ) {
        setClientEmail2("Please enter a valid email");
      } else {
        setClientEmail2(undefined);
      }
      if (document.getElementById("clientLastId2").value === "") {
        setClientLName2("Last name cannot be blank");
      } else {
        setClientLName2(undefined);
      }
      if (document.getElementById("clientPhoneId2").value === "") {
        setClientPhone2("Phone number cannot be blank");
      } else if (
        !/^(?:\+91|0|1)?[ -]?(?:(?:\()?[0-9]{3}(?:\))?[ -]?[0-9]{3}[ -]?[0-9]{4}|[0-9]{10}|(?:[24-9][0-9]{2}|3[01][0-9]{2}|5[56][0-9]{2}|6[0-9]{3})[ -]?[0-9]{4}|(?:8(?:00|55|66|77|88))[ -]?[0-9]{3}[ -]?[0-9]{4})$/.test(
          document.getElementById("clientPhoneId2").value
        )
      ) {
        setClientPhone2("Please enter a valid phone number");
      } else {
        setClientPhone2(undefined);
      }
      if (
        !(
          document.getElementById("clientFirstId2").value === "" ||
          document.getElementById("clientPhoneId2").value === "" ||
          document.getElementById("clientEmailId2").value === "" ||
          !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            document.getElementById("clientEmailId2").value
          ) ||
          document.getElementById("clientLastId2").value === ""
        ) &&
        /^(?:\+91|0|1)?[ -]?(?:(?:\()?[0-9]{3}(?:\))?[ -]?[0-9]{3}[ -]?[0-9]{4}|[0-9]{10}|(?:[24-9][0-9]{2}|3[01][0-9]{2}|5[56][0-9]{2}|6[0-9]{3})[ -]?[0-9]{4}|(?:8(?:00|55|66|77|88))[ -]?[0-9]{3}[ -]?[0-9]{4})$/.test(
          document.getElementById("clientPhoneId2").value
        )
      ) {
        setTimeout(() => {
          AddNewClientRequest((response) => {
            const { data, message, success } = response.data;
            if (response.data.success) {
              toast.success(message);
              getDetailsFetch();
              if (data) {
                const ci = data[0].client_id;
                const cn = data[0].client_name;

                const total = { id: ci, name: cn };
                clientName.push(total);
                HandleClose();

                HandleClose2();
              }
              if (isNewClient2 && isNewClient) {
                // setActiveStep(activeStep + 1);
                handleNewClient2();
                setIsNewClient2(false);
                setIsNewClient(false);
              } else {
                setActiveStep(activeStep);
              }
            } else if (!response.data.success) {
              toast.error(response.data.message);
            } else {
              toast.error(response.data.message);
            }
          }, formdata2);
        }, 3000);
      }
    }

    if (activeStep === 3 && !isNewAgent) {
      setActiveStep(activeStep + 1);
    }
    if (activeStep === 3 && isNewAgent) {
      if (document.getElementById("clientFirstId3").value === "") {
        setClientFName3("Name cannot be blank");
      } else {
        setClientFName3(undefined);
      }
      if (document.getElementById("clientEmailId3").value === "") {
        setClientEmail3("Email cannot be blank");
      } else if (
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          document.getElementById("clientEmailId3").value
        )
      ) {
        setClientEmail3("Please enter a valid email");
      } else {
        setClientEmail3(undefined);
      }
      if (document.getElementById("clientLastId3").value === "") {
        setClientLName3("Last name cannot be blank");
      } else {
        setClientLName3(undefined);
      }
      if (
        !(
          document.getElementById("clientFirstId3").value === "" ||
          document.getElementById("clientEmailId3").value === "" ||
          !/\S+@\S+\.\S+/.test(
            document.getElementById("clientEmailId3").value
          ) ||
          document.getElementById("clientLastId3").value === ""
        )
      ) {
        AddNewAgentRequest((response) => {
          const { data, message, success } = response.data;
          if (response.data.success) {
            toast.success(message);
            getDetailsFetch();
            if (data) {
              const ci = data[0].agent_id;
              const cn = data[0].agent_name;

              const total = { id: ci, name: cn };

              buyerAgent2.push(total);
            }
            if (isNewAgent) {
              setActiveStep(activeStep + 1);
              setIsNewAgent(false);
            } else {
              setActiveStep(activeStep);
            }
          } else if (!response.success) {
            toast.error(response.message);
          } else {
            toast.error(response.message);
          }
        }, formdata3);
      }
    }
    if (activeStep === 4) {
      if (selectedServices.length === 0) {
        setDisableSchedule3(false);
        setActiveStep(activeStep);
      } else if (selectedServices.length > 0) {
        setDisableSchedule3(true);
        // setActiveStep(activeStep + 1);
      }
    }
  };
  function makePdfAndUploadAgreement(pdfData, name, orderId, index, length) {
    convertedPDFArray.push(pdfData);
    agreementNameArray.push(name);
    if (
      agreementNameArray.length === length &&
      convertedPDFArray.length === length
    )
      uploadUnsignedAgreementPDF(
        convertedPDFArray,
        agreementNameArray,
        orderId
      );
  }
  const fontSizeConverter = (val) => {
    switch (val) {
      case "1":
        return 10;
      case "2":
        return 13;
      case "3":
        return 16;
      case "4":
        return 18;
      case "5":
        return 24;
      case "6":
        return 32;
      case "7":
        return 48;
      default:
        return val;
    }
  };
  function convertFontTagsToInlineStyles(htmlString) {
    let regex = /style="([^"]*)font-family:([^"]*)"/g;
    const regexhtml = /<br\s*\/?>|&nbsp;/g;
    // Replace <font> tags with inline styles
    var convertedString = htmlString
      ?.replace(/<font\s+color="([^"]*)"\s*face="([^"]*)">(.*?)<\/font>/g, "<span style=\"color: $1; font-family: 'Open Sans';\">$3</span>")
      ?.replace(regex, 'style="$1"')
      ?.replace(/<font([^>]*)>/gi, function (match, p1) {
        var attributes = p1?.split(/\s+/);
        var styles = "";
        // Convert font attributes to inline styles
        for (var i = 0; i < attributes?.length; i++) {
          var attribute = attributes[i]?.trim();
          var parts = attribute?.split("=");
          if (parts.length === 2) {
            var name = parts[0]?.toLowerCase();
            var value = parts[1]?.replace(/['"]/g, "");
            // eslint-disable-next-line
            switch (name) {
              case "color":
                styles += "color: " + value + "; ";
                break;
              case "size":
                styles += "font-size: " + fontSizeConverter(value) + "px !important; ";
                break;
            }
          }
        }
        return "<span style='" + styles + "'>";
      });
    convertedString = convertedString?.replace(/<\/font>/gi, "</span>");

    return convertedString;
  }

  const HTMLtoPDF = (data, inspOrderId) => {
    data.map((agreement, index) => {
      let modifiedHtml = convertFontTagsToInlineStyles(agreement?.agreement_body);
      const regexPattern = /in\\|in;|in(?=")/g;
      const replacedString = modifiedHtml?.replace(regexPattern, "px");
      let regexPatternForSymbols = /\"=\"\"/g;
      const replacedStringForSymbols = replacedString?.replace(regexPatternForSymbols, "");
      let html = htmlToPdfmake(replacedStringForSymbols);
      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      pdfMake.fonts = {
        ComicSansMs: {
          normal:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
          bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
          italics:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
          bolditalics:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
        },
        Arial: {
          normal:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
          bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
          italics:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
          bolditalics:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
        },
        Roboto: {
          normal:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
          bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
          italics:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
          bolditalics:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
        },
        Tahoma: {
          normal:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
          bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
          italics:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
          bolditalics:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
        },
        CourierNew: {
          normal:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
          bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
          italics:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
          bolditalics:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
        },
        Impact: {
          normal:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
          bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
          italics:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
          bolditalics:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
        },
        Georgia: {
          normal:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
          bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
          italics:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
          bolditalics:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
        },
        TrebuchetMs: {
          normal:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
          bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
          italics:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
          bolditalics:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
        },
        Verdana: {
          normal:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
          bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
          italics:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
          bolditalics:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
        },
        OpenSans: {
          normal:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
          bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
          italics:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
          bolditalics:
            "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
        },
      };
      var documentDefinition = {
        content: html,
        defaultStyle: { font: "Roboto" },
      };
      const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
      pdfDocGenerator.getBase64((pdfdata) => {
        makePdfAndUploadAgreement(
          pdfdata,
          agreement.name,
          inspOrderId,
          index,
          data.length
        );
      });
    });
  };

  // agreement details API
  const GetAgreementDetails = (inspOrderId) => {
    let selectedAgrmtIds = agreementChangeValue.map((item) => item.id);
    // setShowLoader(true);
    const formdata = {
      company_id: companyId,
      user_id: uuId,
      role_id: roleId,
      agreement_id: selectedAgrmtIds.filter(
        (item, index) => selectedAgrmtIds.indexOf(item) === index
      ),
      order_id: inspOrderId,
    };
    apiCall(
      (response) => {
        const { data, message, success } = response.data;
        if (success) {
          HTMLtoPDF(data, inspOrderId);
          setShowLoader(false);
        } else {
          toast.error(message);
        }
      },
      formdata,
      "GET_AGREEMENT_CONTENT"
    );
  };

  // uploading agreementpdf on inspection schedule
  const uploadUnsignedAgreementPDF = (
    convertedPdf,
    agreementNameArray,
    inspOrderId
  ) => {
    let selectedAgrmtIds = agreementChangeValue.map((item) => item.id);
    const formdata = {
      user_id: uuId,
      company_id: companyId,
      role_id: roleId,
      inspection_id: inspOrderId,
      agreement_file: convertedPdf,
      agreement_id: selectedAgrmtIds.filter(
        (item, index) => selectedAgrmtIds.indexOf(item) === index
      ),
      agreement_file_name: agreementNameArray,
    };
    apiCall(
      (response) => {
        const { message, success } = response.data;
        if (success) {
          // toast.success(message);
          setShowLoader(false);
          convertedPDFArray = [];
          agreementNameArray = [];
        } else {
          toast.error(message);
          setShowLoader(false);
        }
      },
      formdata,
      "UPLOAD_UNSIGNED_AGREEMENT"
    );
  };
  let selectedAgrmtIds = agreementChangeValue.map((item) => item.id);

  const SwitchSteps = (index) => {
    setActiveStep(index);

    if (selectedInspectors.length === 0) {
      setDisableSche(false);
    } else {
      setDisableSche(true);
    }

    if (showTooltip) {
      if (document.getElementById("PropertyAddressId").value === "") {
        setAddressValid("Address cannot be blank");
      } else {
        setAddressValid(undefined);
      }
      if (document.getElementById("PropertyCityId").value === "") {
        setCityValid("city cannot be blank");
      } else {
        setCityValid(undefined);
      }
      if (document.getElementById("PropertyStateId").value === "") {
        setStateValid("state cannot be blank");
      } else {
        setStateValid(undefined);
      }

      if (document.getElementById("PropertyZipId").value === "") {
        setZipValid("zip cannot be blank");
      } else {
        setZipValid(undefined);
      }
    }

    if (
      location_address === "" &&
      location_city === "" &&
      location_state === "" &&
      location_zip === ""
    ) {
      setDisableSchedule(false);
    } else if (
      location_address === null &&
      location_city === null &&
      location_state === null &&
      location_zip === null
    ) {
      setDisableSchedule(false);
    } else if (
      !(
        location_address !== "" &&
        location_city !== "" &&
        location_state !== "" &&
        location_zip !== ""
      )
    ) {
      setDisableSchedule(false);
    } else {
      setDisableSchedule(true);
    }

    if (clientIds.length > 0) {
      setDisableSchedule2(true);
    } else {
      setDisableSchedule2(false);
    }

    // if (selectedInspectors.length > 0 && address.location_address !== "" && address.location_city !== "" && address.location_state !== "" && address.location_zip !== "" && clientIds.length > 0) {
    //   setDisableSche(true);
    //   setDisableSchedule(true);
    //   setDisableSchedule2(true);
    //   setDisableSchedule3(true);
    // } else {
    //   setDisableSche(false);
    //   setDisableSchedule(false);
    //   setDisableSchedule2(false);
    //   setDisableSchedule3(false);
    // }
  };

  const [browserTimeZone, setBrowserTimeZone] = useState("");

  useEffect(() => {
    const getTimeZone = () => {
      if (
        typeof Intl === "object" &&
        typeof Intl.DateTimeFormat === "function"
      ) {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        setBrowserTimeZone(timeZone);
      } else {
        // Fallback in case the browser doesn't support the Intl object
        setBrowserTimeZone("Unknown");
      }
    };

    getTimeZone();
  }, []);

  useEffect(() => {
    if (
      (activeStep === ScheduleInspectionSteps.length - 1 &&
        Type === "schedule") ||
      Type === "edit"
    ) {
      handleNext();
    }
  }, [
    next &&
    activeStep === ScheduleInspectionSteps.length - 1 &&
    Type === "schedule",
  ]);

  useEffect(() => {
    if (selectedAgrmtIds.length > 0 || selectedTemplate.length > 0) {
      SwitchSteps(4);
    }
  }, [selectedAgrmtIds.length > 0 || selectedTemplate.length > 0]);
  var date = dayjs(dateTime).utc().format("YYYY-MM-DD HH:mm:ss");
  const handleNext = (data) => {
    // data.preventDefault();

    let selectedAgrmtIds = agreementChangeValue.map((item) => item.id);
    if (
      activeStep === ScheduleInspectionSteps.length - 1 &&
      Type === "schedule"
    ) {
      if (selectedServices.length === 0) {
        setServicesValid(1);
        // setActiveStep(activeStep);
        setActiveStep((prev) => prev);
      } else if (selectedServices.length > 0) {
        setServicesValid(0);
        setShowLoader(true);
        let customDiscount = [];
        orderdiscounts.forEach((value) =>
          customDiscount.push({
            id: value?.id !== undefined ? value.id : null,
            description: value.description,
            discount: value.price * -1,
          })
        );
        const formdata = {
          company_id: companyId,
          user_id: uuId,
          role_id: roleId,
          inspector_id: Inspectors,
          inspection_datetime: date,
          timezone: browserTimeZone,
          location_address: address.location_address,
          location_address_2: address.location_address_2,
          location_city: address.location_city,
          location_state: address.location_state,
          local_starttime: dateTime,
          location_zip: address.location_zip,
          // agent_id: buyerAgentId,
          buyer_agent_id: buyerAgentId, //buyer agent id (anil)
          seller_agent_id: sellerAgentIds, //seller agent id (anil)
          client_id: clientIds,
          service_id: services,
          order_templates: templateId,
          charges: totalPrice,
          service_duration: minutes,
          order_charges: ordercharge,
          order_discounts: customDiscount,
          // agreement_id:selectedAgreementsIDArray,
          agreement_id: selectedAgrmtIds.filter(
            (item, index) => selectedAgrmtIds.indexOf(item) === index
          ),
          status: 2,
          local_agreement_send_time: moment(currentDate).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
        };

        ScheduleNewInspection((response) => {
          toast.dismiss();
          const { data, message, success, status_code } = response.data;
          if (success) {
            RealEstateAPI((response) => {
              if (response?.data?.success) {
                console.log(response?.data)
              }
            }, { company_id: companyId, inspection_id: data })
            sessionStorage.setItem("is_trial", response.data.is_trial);
            sessionStorage.setItem(
              "can_create_inspection",
              response.data.can_create_inspection
            );
            isagreementEnabled &&
              selectedAgrmtIds.filter(
                (item, index) => selectedAgrmtIds.indexOf(item) === index
              )?.length !== 0 &&
              GetAgreementDetails(response?.data?.data); //uploading unsigned agreement starts here
            setShowLoader(false);
            Userpilot.track("Schedule Inspection");
            setActiveStep(activeStep + 1);
          } else {
            // toast.error(message);
            setShowLoader(false);
            if (
              status_code === "422" &&
              message ===
              "You can't schedule the inspection as your total inspections exceeded to 5. Please upgrade your subscription."
            )
              setOpenSnackbar(true);
            else toast.error(message);
          }
        }, formdata);
        // setActiveStep(activeStep + 1);
      }
    } else if (
      activeStep === ScheduleInspectionSteps.length - 1 &&
      Type === "edit"
    ) {
      if (selectedServices.length === 0) {
        setServicesValid(1);
        setActiveStep((prev) => prev);
      } else {
        setServicesValid(0);
      }

      if (selectedTemplate.length === 0) {
        setTemplateValid(1);
        setActiveStep((prev) => prev);
      } else {
        setTemplateValid(0);
      }

      if (agreementChangeValue.length === 0) {
        setAgreeValid(1);
        setActiveStep((prev) => prev);
      } else {
        setAgreeValid(0);
      }

      if (
        !(
          selectedServices.length === 0 &&
          selectedTemplate.length === 0 &&
          agreementChangeValue.length === 0
        )
      ) {
        setShowLoader(true);
        let customDiscount = [];
        orderdiscounts.forEach((value) =>
          customDiscount.push({
            id: value.id !== undefined ? value.id : null,
            description: value.description,
            discount: value.price * -1,
          })
        );
        const formdata = {
          company_id: companyId,
          user_id: uuId,
          role_id: roleId,
          inspection_id: queryId,
          inspector_id: Inspectors,
          start_time: dateTime,
          timezone: browserTimeZone,
          location_address: address.location_address,
          location_address_2: address.location_address_2,
          location_city: address.location_city,
          location_state: address.location_state,
          inspection_datetime: date,
          location_zip: address.location_zip,
          // agent_id: buyerAgentId,
          buyer_agent_id: buyerAgentId, //buyer agent id (anil)
          seller_agent_id: sellerAgentIds, // seller agent id (anil)
          local_starttime: dateTime,
          // agent_id: agentIds,
          client_id: clientIds,
          service_id: services,
          order_templates: templateId,
          charges: totalPrice,
          service_duration: minutes,
          order_charges: ordercharge,
          order_discounts: customDiscount,
          // agreement_id:selectedAgreementsIDArray,
          agreement_id: selectedAgrmtIds.filter(
            (item, index) => selectedAgrmtIds.indexOf(item) === index
          ),
          status: 2,
          local_agreement_send_time: moment(currentDate).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
        };
        EditInspectionAPI((response) => {
          toast.dismiss();
          // const { data, message, success, status_code } = response.data;
          //api
          // console.log("edit agent api ",data)
          if (response?.data?.success) {
            RealEstateAPI((response) => {
              if (response.data.success) {
                console.log(response.data)
              }
            }, { company_id: companyId, inspection_id: queryId })
            isagreementEnabled &&
              selectedAgrmtIds.filter(
                (item, index) => selectedAgrmtIds.indexOf(item) === index
              )?.length !== 0 &&
              GetAgreementDetails(response?.data?.data); //uploading unsigned agreement starts here
            RealEstateAPI((response) => {
              if (response.data.success) {
                console.log(response.data)
              }
            }, { company_id: companyId, inspection_id: queryId })
            setShowLoader(false);
            setActiveStep(activeStep + 1);
          } else {
            setShowLoader(false);
            toast.error(response?.data?.message);
            if (
              response?.data?.status_code === "422" &&
              response?.data?.message ===
              "You can't schedule the inspection as your total inspections exceeded to 5. Please upgrade your subscription."
            ) {
              setOpenSnackbar(true);
            } else {
              setShowLoader(false);
            }
          }
        }, formdata);
      }
    }
    validate(activeStep);
  };

  const handleDraft = () => {
    setShowLoader(true);
    let selectedAgrmtIds = agreementChangeValue.map((item) => item.id);
    let customDiscount = [];
    orderdiscounts.forEach((value) =>
      customDiscount.push({
        id: value?.id !== undefined ? value.id : null,
        description: value.description,
        discount: value.price * -1,
      })
    );

    if (Type === "schedule") {
      const formdata = {
        company_id: companyId,
        user_id: uuId,
        role_id: roleId,
        inspector_id: Inspectors,
        inspection_datetime: date,
        timezone: browserTimeZone,
        location_address: address.location_address,
        location_address_1: address.location_address_2,
        location_city: address.location_city,
        location_state: address.location_state,
        location_zip: address.location_zip,
        agent_id: buyerAgentId,
        buyer_agent_id: buyerAgentId,
        seller_agent_id: sellerAgentIds,
        client_id: clientIds,
        local_starttime: dateTime,
        service_id: services,
        order_templates: templateId,
        charges: totalPrice,
        service_duration: minutes,
        order_charges: ordercharge,
        order_discounts: customDiscount,
        // agreement_id:selectedAgreementsIDArray,
        agreement_id: selectedAgrmtIds.filter(
          (item, index) => selectedAgrmtIds.indexOf(item) === index
        ),
        status: 1,
        local_agreement_send_time: moment(currentDate).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      };
      ScheduleNewInspection((response) => {
        toast.dismiss();
        const { data, message, success, status_code } = response.data;
        if (success) {
          setShowLoader(false);
          RealEstateAPI((response) => {
            if (response.data.success) {
              console.log(response.data)
            }
          }, { company_id: companyId, inspection_id: data })
          sessionStorage.setItem("is_trial", response.data.is_trial);
          sessionStorage.setItem(
            "can_create_inspection",
            response.data.can_create_inspection
          );
          Userpilot.track("Schedule Inspection");
          setActiveStep(ScheduleInspectionSteps?.length + 1);

        } else {
          toast.error(message);
          setShowLoader(false);
          if (
            status_code === "422" &&
            message ===
            "You can't schedule the inspection as your total inspections exceeded to 5. Please upgrade your subscription."
          )
            setOpenSnackbar(true);
        }
      }, formdata);
    } else if (Type === "edit") {
      const formdata = {
        company_id: companyId,
        user_id: uuId,
        role_id: roleId,
        inspection_id: queryId,
        inspector_id: Inspectors,
        inspection_datetime: date,
        timezone: browserTimeZone,
        start_time: date,
        location_address: address.location_address,
        location_address_2: address.location_address_2,
        location_city: address.location_city,
        location_state: address.location_state,
        location_zip: address.location_zip,
        agent_id: buyerAgentId,
        buyer_agent_id: buyerAgentId,
        seller_agent_id: sellerAgentIds,
        local_starttime: dateTime,
        client_id: clientIds,
        service_id: services,
        order_templates: templateId,
        charges: totalPrice,
        service_duration: minutes,
        order_charges: ordercharge,
        order_discounts: customDiscount,
        // agreement_id:selectedAgreementsIDArray,
        agreement_id: selectedAgrmtIds.filter(
          (item, index) => selectedAgrmtIds.indexOf(item) === index
        ),
        status: 1,
        local_agreement_send_time: moment(currentDate).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      };
      EditInspectionAPI((response) => {
        toast.dismiss();
        // const { data, message, success, status_code } = response.data;
        // console.log("edit inspection api",data.orderagent)
        if (response?.data?.success) {
          setShowLoader(false);
          RealEstateAPI((response) => {
            if (response.data.success) {
              console.log(response.data)
            }
          }, { company_id: companyId, inspection_id: queryId })
          setActiveStep(ScheduleInspectionSteps?.length + 1);
        } else {
          setShowLoader(false);
          toast.error(response?.data?.message);
          if (
            response?.data?.status_code === "422" &&
            response?.data?.message ===
            "You can't schedule the inspection as your total inspections exceeded to 5. Please upgrade your subscription."
          )
            setOpenSnackbar(true);
        }
      }, formdata);
    }
  };
  // changes for agreement non mandatory
  const pp = isagreementEnabled
    ? agreementChangeValue.length === 0
      ? true
      : true
    : true;
  const aa = agreementChangeValue.length === 0 ? false : true;
  const tt = selectedTemplate.length !== 0 ? true : false;
  const ss = selectedServices.length !== 0 ? true : false;
  const slectedAgree = pp && tt && ss;

  return (
    <>
      <Snackbar
        className="snackbar"
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          Your 5 free trial inspection schedule has ended..Please subscribe to
          schedule unlimited inspections
        </Alert>
      </Snackbar>
      <Preloader showPreloader={showLoader} />
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          className={
            isTaborMobile
              ? "schedule-inspection-container"
              : "schedule-inspection-container-light"
          }
        >
          <Grid item xs="none" md={1}></Grid>
          <Grid item xs={12} md={10} className="modal-container">
            <Typography align="right" className="close-icon">
              <CloseIcon
                onClick={() =>
                  location.state?.calendar
                    ? navigate("/calendar")
                    : navigate("/inspections")
                }
              />
            </Typography>

            <div
              className={
                !isTaborMobile
                  ? "inspection-data-modal-light"
                  : "inspection-data-modal"
              }
            >
              {activeStep === ScheduleInspectionSteps?.length ||
                activeStep === ScheduleInspectionSteps?.length + 1 ? (
                <ScheduleMessage
                  navigate={navigate}
                  location={location}
                  activeStep={activeStep}
                  ScheduleInspectionSteps={ScheduleInspectionSteps}
                />
              ) : (
                <>
                  <Typography
                    className={
                      isTaborMobile
                        ? "modal-title global-font"
                        : "modal-title-light global-font"
                    }
                  >
                    Schedule an Inspection
                  </Typography>
                  <Stepper
                    nonLinear
                    activeStep={activeStep}
                    style={{ padding: "58px 19px" }}
                  >
                    {ScheduleInspectionSteps?.map((label, index) => (
                      <Step key={label}>
                        <StepButton
                          onClick={() => {
                            SwitchSteps(index);
                          }}
                        >
                          <StepLabel className="step-label">{label}</StepLabel>
                        </StepButton>
                      </Step>
                    ))}
                  </Stepper>
                  <form style={{ minHeight: "150px" }}>
                    <div
                      style={{ display: activeStep === 0 ? "block" : "none" }}
                    >
                      <BasicDetails
                        selectedInspectors={selectedInspectors}
                        inspectionId={inspectionId}
                        inspList={filterinspector}
                        Type={Type}
                        editRefresh={props.editRefresh}
                        InspectorChange={InspectorChange}
                        queryId={queryId}
                        editValues={editValues}
                        getDateTime={(timestramp) => getDateTime(timestramp)}
                      />
                    </div>
                    <div
                      style={{ display: activeStep === 1 ? "block" : "none" }}
                    >
                      <PropertyDetails
                        address={address}
                        addressValid={addressValid}
                        cityValid={cityValid}
                        Type={Type}
                        editRefresh={props.editRefresh}
                        editValues={editValues}
                        stateValid={stateValid}
                        zipValid={zipValid}
                        handleAddress={handleAddress}
                      />
                    </div>
                    <div
                      style={{ display: activeStep === 2 ? "block" : "none" }}
                    >
                      <ClientDetails
                        clientName2={clientName2}
                        handleChangeClient2={handleChangeClient2}
                        HandleClose={HandleClose}
                        HandleClose2={HandleClose2}
                        clientName={clientName}
                        clientFName={clientFName}
                        clientPhone2={clientPhone2}
                        clientPhone={clientPhone}
                        isNewClient2={isNewClient2}
                        clientFName2={clientFName2}
                        clientEmail2={clientEmail2}
                        clientLName2={clientLName2}
                        handleInput={handleInput}
                        handleNewClient2={handleNewClient2}
                        clientEmail={clientEmail}
                        inputValue={inputValue}
                        inputValue2={inputValue2}
                        handleUser2={handleUser2}
                        user={user}
                        handleInput2={handleInput2}
                        user2={user2}
                        handleUser={handleUser}
                        clientValid={clientValid}
                        clientLName={clientLName}
                        isNewClient={isNewClient}
                        clientList={clientList}
                        handleNewClient={handleNewClient}
                        handleChangeClient={handleChangeClient}
                      />
                    </div>
                    <div
                      style={{ display: activeStep === 3 ? "block" : "none" }}
                    >
                      <AgentDetails
                        uuId={uuId}
                        user3={user3}
                        inputValue3={inputValue3}
                        handleUser3={handleUser3}
                        handleInput3={handleInput3}
                        clientFName2={clientFName3}
                        clientEmail2={clientEmail3}
                        clientLName3={clientLName3}
                        handleNewAgent={handleNewAgent}
                        isNewAgent={isNewAgent}
                        roleId={roleId}
                        agentsLists={agentsLists}
                        handleChangeAgent={handleChangeAgent}
                        handleBuyerAgent={handleBuyerAgent}
                        handleSellerAgent={handleSellerAgent}
                        handleSellerAgent2={handleSellerAgent2}
                        buyerAgent2={buyerAgent2}
                        buyerAgent={buyerAgent}
                        sellerAgent={sellerAgent}
                        sellerAgent2={sellerAgent2}
                        AddNewAgentRequest={AddNewAgentRequest}
                        setBuyerAgent2={setBuyerAgent2}
                        setSellerAgent2={setSellerAgent2}
                        setSellerAgent={setSellerAgent}
                        getDetailsFetch={getDetailsFetch}
                        formdata3={formdata3}
                        setBuyerAgent={setBuyerAgent}
                      />
                    </div>
                    <div
                      style={{ display: activeStep === 4 ? "block" : "none" }}
                    >
                      <Services
                        IsCustomCharges={(status) => IsCustomCharges(status)}
                        agreeValid={agreeValid}
                        errors={errors}
                        setErrors={setErrors}
                        dataIsCorrect={dataIsCorrect}
                        setDataIsCorrect={setDataIsCorrect}
                        showCustomAddInput={showCustomAddInput}
                        showDiscountInput={showDiscountInput}
                        setShowCustomAddInput={setShowCustomAddInput}
                        setShowDiscountInput={setShowDiscountInput}
                        templateValid={templateValid}
                        servicesValid={servicesValid}
                        handleTotalPrice={(price) => handleTotalPrice(price)}
                        servicesLists={servicesLists}
                        handleOrders={(charges, discounts) =>
                          handleOrders(charges, discounts)
                        }
                        Type={Type}
                        editRefresh={props.editRefresh}
                        editValues={editValues}
                        templateList={templateList}
                        allTemplateList={allTemplateList}
                        ServicesChange={ServicesChange}
                        selectedTemplate={selectedTemplate}
                        selectedServices={selectedServices}
                        TemplateChange={TemplateChange}
                        selectedAgreementListValue={selectedAgreementListValue}
                        agreementsList={agreementsList}
                        agreementChange={agreementChange}
                        agreementChangeValue={agreementChangeValue}
                        isagreementEnabled={isagreementEnabled}
                        setOrderCharge={setOrderCharge}
                        setOrderDiscounts={setOrderDiscounts}
                      />
                    </div>

                    {/* mobile button section */}
                    <ScheduleButtons
                      errors={errors}
                      Type={Type}
                      editValues={editValues}
                      NextData={() => setNext(next + 1)}
                      setErrors={setErrors}
                      setDataIsCorrect={setDataIsCorrect}
                      showCustomAddInput={showCustomAddInput}
                      showDiscountInput={showDiscountInput}
                      aa={pp}
                      setShowTooltip={setShowTooltip}
                      showTooltip={showTooltip}
                      setAddressValid={setAddressValid}
                      setCityValid={setCityValid}
                      setServicesValid={setServicesValid}
                      tt={tt}
                      setTemplateValid={setTemplateValid}
                      setAgreeValid={setAgreeValid}
                      setStateValid={setStateValid}
                      setZipValid={setZipValid}
                      selectedAgrmtIds={agreementChangeValue.map(
                        (item) => item.id
                      )}
                      disableSche={disableSche}
                      isSchedule={isSchedule}
                      isMobile={isMobile}
                      navigate={navigate}
                      location={location}
                      handleNext={handleNext}
                      disableSchedule={disableSchedule}
                      disableSchedule2={disableSchedule2}
                      ss={ss}
                      disableSchedule3={slectedAgree}
                      // changes for agreement non mandatory
                      agreementSelected={
                        isagreementEnabled
                          ? agreementChangeValue.length === 0
                            ? true
                            : true
                          : true
                      }
                      ChargeValid={ChargeValid}
                      activeStep={activeStep}
                      ScheduleInspectionSteps={ScheduleInspectionSteps}
                      handleBack={() => setActiveStep(activeStep - 1)}
                      setActiveStep={setActiveStep}
                      handleDraft={handleDraft}
                      selectedTemplate={selectedTemplate}
                    />
                  </form>
                </>
              )}
            </div>
          </Grid>
          <Grid item xs="none" md={1}></Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ScheduleLayout;
