import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import HtmlCommentEditor from "./HtmlCommentEditor";
import { filterObjectsUptoCategory, timestamp } from "../../utils/services";
import uuid from "react-uuid";

const froalaConfig = {
  imagePaste: false,
  spellCheck: true,
  heightMin: 145,
  // heightMax: 170,
  pluginsEnabled: ["wordPaste", "spellChecker", "codeBeautifier", "fontFamily", "fontSize", "colors", "align", "lists"],
};

const toolbarButtonsConfig = {
  moreText: {
    buttons: ["bold", "italic", "underline", "fontFamily", "fontSize", "textColor", "backgroundColor", "alignLeft", "alignCenter", "alignRight"],
    align: "left",
    buttonsVisible: 3,
  },
  moreRich: {
    buttons: ["insertLink", "emoticons", "specialCharacters", "fontAwesome", "formatOLSimple", "formatUL", "outdent", "indent"],
    align: "left",
    buttonsVisible: 0,
  },
  moreMisc: { buttons: ["undo", "redo"], align: "right", buttonsVisible: 2 },
};

const InformationSection = (props) => {
  const { informationData, categoryId, isPlaceHolder, item, lineId, lineInputControlId, AllCategoryList, updateTemplate } = props;
  const [editorContent, setEditorContent] = useState({ innerHTML: informationData || "" });
  let lineIndex = null;
  let infoIndex = null;
  let catList = AllCategoryList?.template?.categories;
  const categoriesDataIndex = catList.findIndex((cat) => cat.category_id === categoryId);
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.templates)?.categoryList;
  const UpdateInfoData = () => {
    if (categoriesDataIndex !== -1) {
      lineIndex = catList[categoriesDataIndex].lines.findIndex((line) => line.line_id === lineId);
      infoIndex = catList[categoriesDataIndex].lines[lineIndex].line_input_controls.findIndex((info) => info.line_input_control_id === lineInputControlId);
      if (infoIndex === -1) {
        const inputControlId = uuid();
        infoIndex = 0;
        catList[categoriesDataIndex].lines[lineIndex].line_input_controls.push({
          data: editorContent?.innerHTML,
          type: "Information",
          prompt: "Information",
          uuid: inputControlId,
          line_input_control_id: inputControlId,
          created_at: timestamp(),
          updated_at: timestamp(),
          values: [{ value: editorContent?.innerHTML }],
        });
      } else {
        catList[categoriesDataIndex].lines[lineIndex].line_input_controls[infoIndex].values = [{ value: editorContent?.innerHTML }];
        catList[categoriesDataIndex].lines[lineIndex].line_input_controls[infoIndex].data = editorContent?.innerHTML;
        catList[categoriesDataIndex].lines[lineIndex].line_input_controls[infoIndex].updated_at = timestamp();
      }
    }
    filterObjectsUptoCategory(catList, categoryId, dispatch, categories);
    updateTemplate("noToast", "", false);
  };

  return (
    <Grid sx={{ marginBottom: "52px", marginTop: "12px" }}>
      <p className="line-sub-head">Informational text</p>
      <HtmlCommentEditor update={UpdateInfoData} editorContent={editorContent} setEditorContent={setEditorContent} froalaConfig={froalaConfig} toolbarButtonsConfig={toolbarButtonsConfig} />
    </Grid>
  );
};

export default InformationSection;
