import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Grid, Button } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import validator from "validator";
import axios from "axios";
import toast from "react-hot-toast";
import { apiCall } from "../../utils/action";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Checkbox from "@mui/material/Checkbox";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import Preloader from "../../helpers/Preloader";
// pdf converts
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import htmlToPdfmake from "html-to-pdfmake";
// pdf converts

const { detect } = require("detect-browser");
const browser = detect();

const AgreementWebViewNSignature = () => {
  const [showLoader, setShowLoader] = useState();
  const [date1, setDate1] = useState(new Date());
  const [ipAddress, setIPAdress] = useState("");
  const [date1Error, setDate1Error] = useState(false);
  const [date1ErrorText, setDate1ErrorText] = useState("");
  const [date2, setDate2] = useState(new Date());
  const [clientNameOne, setClientNameOne] = useState("");
  const [clientNameOneError, setClientNameOneError] = useState("");
  const [clientNameOneErrorText, setClientNameOneErrorText] = useState("");
  const [clientNameTwo, setClientNameTwo] = useState("");
  const [clientNameTwoError, setClientNameTwoError] = useState("");
  const [agreementContent, setAgreementContent] = useState("");
  const [agreementName, setAgreementName] = useState("");
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [pdfFile, setPdfFile] = useState();
  let navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let agreementIdParam = Number(searchParams.get("aid"));
  let companyIdParam = searchParams.get("cid");
  let orderIdParam = searchParams.get("oid");
  let userIdParam = searchParams.get("uid");
  let uniqueIdParam = searchParams.get("uniq");
  let emailParamEncoded = encodeURIComponent(searchParams.get("email"));
  let emailParam = decodeURIComponent(emailParamEncoded.replace(/\%20/g, "+"));
  const removeNumericRegex = /\d+/g;

  // Function to remove numeric characters
  function removeNumeric(inputString) {
    return inputString.replace(removeNumericRegex, "").trim();
  }

  function applyStylesToHtmlString(htmlString) {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlString;
    const stylesMap = {
      'fr-fic': {
        'margin': '0 40% !important',
        'text-align': 'center !important'
      },
      'fr-fil': {
        'margin': '0 !important',
        'text-align': 'left !important'
      },
      'fr-dib': {
        'display': 'block !important',
        'float': 'none !important',
        'vertical-align': 'top !important',
      },
      'fr-fir': {
        'margin': '0px 0px 0px 73% !important',
        'text-align': 'right !important'
      },
    };
    const images = tempDiv.querySelectorAll('img');
    images.forEach(img => {
      const existingStyles = img.style.cssText
        .split(';')
        .filter(style => style.trim().length > 0)
        .reduce((styleObj, style) => {
          const [key, value] = style.split(':').map(s => s.trim());
          if (key && value) {
            styleObj[key] = value;
          }
          return styleObj;
        }, {});
      for (const className in stylesMap) {
        if (img.classList.contains(className)) {
          const styles = stylesMap[className];
          for (const [key, value] of Object.entries(styles)) {
            existingStyles[key] = value;
          }
        }
      }
      img.style.cssText = Object.entries(existingStyles)
        .map(([key, value]) => `${key}: ${value};`)
        .join(' ');
    });
    return tempDiv.innerHTML;
  }

  //get agreement details
  const GetAgreementDetails = () => {
    setShowLoader(true);
    const formdata = { user_id: userIdParam, company_id: companyIdParam, agreement_id: [agreementIdParam], order_id: orderIdParam, email: emailParam, unique_id: uniqueIdParam };
    apiCall(
      (response) => {
        const { data, message, success, status_code } = response.data;
        if (success) {
          let imagesUpdatedString = applyStylesToHtmlString(data[0]?.agreement_body);
          let modifiedHtml = convertFontTagsToInlineStyles(imagesUpdatedString);
          let regexPattern2 = /\"=\"\"/g;
          const replacedString2 = modifiedHtml.replace(regexPattern2, "");
          setAgreementContent(replacedString2);
          let agreeName = `${data[0]?.name}.pdf`;
          setAgreementName(agreeName);
          setShowLoader(false);
        } else {
          // navigateing to agreement already signed page if the agreement is already signed
          if (status_code === 400) {
            if (message === "Invalid link.") navigate("/agreement-invalid");
            else navigate("/agreement-signed");
          } else toast.error(message);
          setShowLoader(false);
        }
      },
      formdata,
      "GET_AGREEMENT_CONTENT"
    );
  };

  const getIP = async () => {
    try {
      // Make a request to ipinfo.io to get IP and browser information
      const response = await axios.get("https://ipv4.jsonip.com/");
      setIPAdress(response.data.ip);
    } catch (error) {
      console.error("Error capturing IP and browser info:", error);
    }
  };

  useEffect(() => {
    getIP();
  }, []);

  const sendSignedAgreement = (convertedPdf) => {
    setShowLoader(true);
    toast.dismiss();
    // const formdata = new FormData();
    const formdata = {
      user_id: userIdParam,
      ip_address: ipAddress,
      browser_info: JSON.stringify({ name: browser.name, browser_version: browser.version, os: removeNumeric(browser.os) }),
      company_id: companyIdParam,
      agreement_id: agreementIdParam,
      inspection_id: orderIdParam,
      agreement_file: convertedPdf,
      user_name: `${clientNameOne?.trim()}${clientNameTwo !== "" ? `,${clientNameTwo?.trim()}` : ""}`,
      agreement_signed_date: `${date1},${clientNameTwo !== "" && date2 !== "" ? `,${date2}` : ""}`,
      agreement_file_name: agreementName,
      email: emailParam,
      unique_id: uniqueIdParam,
    };
    apiCall(
      (response) => {
        const { message, success } = response.data;
        if (success) {
          setShowLoader(false);
          navigate("/accept-agreement", { state: { emailParam } });
        } else {
          toast.error(message);
          console.log("else ", message);
          setShowLoader(false);
        }
      },
      formdata,
      "UPLOAD_SIGNED_AGREEMENT"
    );
  };

  const timestramp1 = moment(date1).format("YYYY-MM-DD");
  const timestramp2 = moment(date2).format("YYYY-MM-DD");

  // signed HTML to PDF conversion
  async function imageToBase64(url) {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  const fetchUrl = () => {
    const currentUrl = window.location.href;
    if (currentUrl.includes("develop") || currentUrl.includes("integration") || currentUrl.includes("localhost")) {
      return "https://cloudfront.portal.palmtech.dev/font-family"
    } else if (currentUrl.includes("staging")) {
      return "https://cloudfront.staging.portal.palmtech.com/font-family"
    } else if (currentUrl.includes("portal")) {
      return "https://cloudfront.portal.palmtech.com/font-family"
    }
  }

  const allowedFonts = ["arial", "impact", "georgia", "tahoma", "times new roman", "verdana", "helvetica", "courier new", "trebuchet ms", "open sans", "segoe ui", "calibri", "cambria", "lato"];

  const HTMLtoPDF = async (data) => {
    let newData = data.replace(/font-family:([^;]*)/gi, function (match, fontFamily) {
      const fontList = fontFamily.split(",").map((font) => font.trim().toLowerCase());
      const normalizedFont = fontList.find((font) =>
        allowedFonts.some((allowedFont) => font.includes(allowedFont.toLowerCase()))
      );
      let finalFont = normalizedFont || "Roboto";
      return `font-family: ${finalFont}`;
    });
    newData = newData.replace(new RegExp("Abadi", "gi"), "Roboto");
    newData = newData.replace(/>\s+</g, '><').replace(/\s{2,}/g, ' ').trim();
    const parser = new DOMParser();
    const doc = parser.parseFromString(data, 'text/html');
    const images = doc.querySelectorAll('img');
    let imagesForPdf = [];
    for (let img of images) {
      if (img.src.startsWith('blob:')) {
        try {
          const borderRadius = img.style.borderRadius || '0px';
          const border = img.style.border || 'none';
          const boxShadow = img.style.boxShadow || 'none';
          const base64 = await imageToBase64(img.src);
          img.src = base64;
          const pdfImage = {
            image: img.src,
            width: img.width,
            height: img.height,
          };
          imagesForPdf.push(pdfImage);
        } catch (error) {
          console.error("Error converting image to base64", error);
        }
      }
    }
    var html = htmlToPdfmake(doc.body.innerHTML?.replace(/^(?!<[^>]+>)(.*)$/gm, "<p>$1</p>"));
    pdfMake.vfs = pdfFonts?.pdfMake?.vfs;
    const newUrl = fetchUrl()
    pdfMake.fonts = {
      Helvetica: {
        normal: `${newUrl}/Helvetica-normal.otf`,
        bold: `${newUrl}/Helvetica-Bold.ttf`,
        italics: `${newUrl}/Helvetica-Italic.ttf`,
        bolditalics: `${newUrl}/Helvetica-BoldItalic.ttf`,
      },
      TimesNewRoman: {
        normal: `${newUrl}/TNR-Normal.ttf`,
        bold: `${newUrl}/TNR-Bold.ttf`,
        italics: `${newUrl}/TNR-Italic.ttf`,
        bolditalics: `${newUrl}/TNR-BoldItalic.ttf`,
      },
      Arial: {
        normal: `${newUrl}/ARIAL.TTF`,
        bold: `${newUrl}/arial-Bold.ttf`,
        italics: `${newUrl}/ArialItalic.ttf`,
        bolditalics: `${newUrl}/ArialBoldItalic.ttf`,
      },
      Abadi: {
        normal: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
        bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
        italics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
        bolditalics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
      },
      Roboto: {
        normal: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
        bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
        italics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
        bolditalics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
      },
      Tahoma: {
        normal: `${newUrl}/tahoma-Regular.ttf`,
        bold: `${newUrl}/tahoma-bold.ttf`,
        italics: `${newUrl}/tahoma-Italic.ttf`,
        bolditalics: `${newUrl}/Tahoma-BoldItalic.ttf`,
      },
      CourierNew: {
        normal: `${newUrl}/CourierNew-Regular.ttf`,
        bold: `${newUrl}/CourierNew-Bold.ttf`,
        italics: `${newUrl}/CourierNew-Italic.ttf`,
        bolditalics: `${newUrl}/CourierNew-BoldItalic.ttf`,
      },
      Impact: {
        normal: `${newUrl}/impact-Normal.ttf`,
        bold: `${newUrl}/impact-Normal.ttf`,
        italics: `${newUrl}/impact-Normal.ttf`,
        bolditalics: `${newUrl}/impact-Normal.ttf`,
      },
      Georgia: {
        normal: `${newUrl}/georgia-Normal.ttf`,
        bold: `${newUrl}/georgia-Bold.ttf`,
        italics: `${newUrl}/georgia-Italic.ttf`,
        bolditalics: `${newUrl}/georgia-BoldItalic.ttf`,
      },
      TrebuchetMs: {
        normal: `${newUrl}/trebuchet-normal.ttf`,
        bold: `${newUrl}/TrebuchetMS-Bold.ttf`,
        italics: `${newUrl}/TrebuchetMS-Italic.ttf`,
        bolditalics: `${newUrl}/TrebuchetMS-BoldItalic.ttf`,
      },
      Verdana: {
        normal: `${newUrl}/verdana-regular.ttf`,
        bold: `${newUrl}/verdana-bold.ttf`,
        italics: `${newUrl}/verdana-italic.ttf`,
        bolditalics: `${newUrl}/verdana-bold-italic.ttf`,
      },
      OpenSans: {
        normal: `${newUrl}/OpenSans-Normal.ttf`,
        bold: `${newUrl}/OpenSans-Bold.ttf`,
        italics: `${newUrl}/OpenSans-Italic.ttf`,
        bolditalics: `${newUrl}/OpenSans-BoldItalic.ttf`,
      },
      SegoeUI: {
        normal: `${newUrl}/SegoeUI-Bold.ttf`,
        bold: `${newUrl}/SegoeUI-Regular.ttf`,
        italics: `${newUrl}/SegoeUI-Italic.ttf`,
        bolditalics: `${newUrl}/SegoeUI-BoldItalic.ttf`,
      },
      Calibri: {
        normal: `${newUrl}/Calibri-Normal.ttf`,
        bold: `${newUrl}/Calibri-Bold.ttf`,
        italics: `${newUrl}/Calibri-Italic.ttf`,
        bolditalics: `${newUrl}/Calibri-boldItalic.ttf`,
      },
      Cambria: {
        normal: `${newUrl}/Cambria-Normal.ttf`,
        bold: `${newUrl}/Cambria-Bold.ttf`,
        italics: `${newUrl}/Cambria-Italic.ttf`,
        bolditalics: `${newUrl}/Cambria-BoldItalic.ttf`,
      },
      Lato: {
        normal: `${newUrl}/Lato-Normal.ttf`,
        bold: `${newUrl}/Lato-Bold.ttf`,
        italics: `${newUrl}/Lato-Italic.ttf`,
        bolditalics: `${newUrl}/Lato-BoldItalic.ttf`,
      },
    };
    var documentDefinition = {
      content: html,
      images: imagesForPdf,
      defaultStyle: { font: "Roboto" },
    };
    pdfMake.createPdf(documentDefinition);
    setPdfFile(pdfMake.createPdf(documentDefinition));
    const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
    pdfDocGenerator?.getBase64((data) => sendSignedAgreement(data));
  };

  const addSignatureNameNDate = () => {
    let signedHTML = `${agreementContent}<p>CLIENT 1</p><p>${clientNameOne} <span>&emsp;&emsp;&emsp;${timestramp1}</span></p>${clientNameTwo !== "" ? `<p>CLIENT 2</p>` : ""
      }<p>${clientNameTwo} <span>&emsp;&emsp;&emsp;${clientNameTwo !== "" ? timestramp2 : ""}</span></p>`;
    HTMLtoPDF(signedHTML);
  };
  const validate = () => {
    let checkboxSpan = document.getElementById("agreeTermsID");
    checkboxSpan.innerHTML = "";
    if (clientNameOne?.trim() === "" || clientNameOne?.trim() === null) {
      setClientNameOneError(true);
      setClientNameOneErrorText("Client name is required");
    } else if (!validator.isLength(clientNameOne?.trim(), { min: 3, max: undefined })) {
      setClientNameOneError(true);
      setClientNameOneErrorText("Minimum 3 characters required");
    } else setClientNameOneError(false);
    if (date1 === null) {
      setDate1Error(true);
      setDate1ErrorText("Date is required");
    } else setDate1Error(false);
    // if(clientNameTwo === '') setDate2(null);
    if (clientNameOne?.trim() !== "" && clientNameOne.trim() !== null && validator.isLength(clientNameOne?.trim(), { min: 3, max: undefined }) && date1 !== null && agreeTerms === false)
      checkboxSpan.innerHTML = "Please check the terms and conditions";
    if (clientNameOne !== "" && clientNameOne !== null && validator.isLength(clientNameOne, { min: 3, max: undefined }) && date1 !== null && agreeTerms === true) {
      addSignatureNameNDate();
    }
  };

  const rejectAgreement = () => {
    setShowLoader(true);
    const formdata = {
      company_id: companyIdParam,
      ip_address: ipAddress,
      browser_info: JSON.stringify({ name: browser.name, browser_version: browser.version, os: removeNumeric(browser.os) }),
      order_id: orderIdParam,
      agreement_id: agreementIdParam,
      email: emailParam,
    };
    apiCall(
      (response) => {
        const { data, message, success } = response.data;
        if (success) {
          setShowLoader(false);
          navigate("/reject-agreement");
        } else {
          console.log("else ", message);
          setShowLoader(false);
        }
      },
      formdata,
      "REJECT_AGREEMENT"
    );
  };

  function convertFontTagsToInlineStyles(htmlString) {
    var convertedString = htmlString
      ?.replace(/<font([^>]*)>/gi, function (match, p1) {
        var attributes = p1?.split(/\s+/);
        var styles = "";
        for (var i = 0; i < attributes?.length; i++) {
          var attribute = attributes[i]?.trim();
          var parts = attribute?.split("=");
          if (parts.length === 2) {
            var name = parts[0]?.toLowerCase();
            var value = parts[1]?.replace(/['"]/g, "");
            // eslint-disable-next-line
            switch (name) {
              case "color":
                styles += "color: " + value + "; ";
                break;
              case "size":
                styles += "font-size: " + fontSizeConverter(value) + "px !important; ";
                break;
            }
          }
        }
        return "<span style='" + styles + "'>";
      });
    convertedString = convertedString?.replace(/<\/font>/gi, "</span>");
    return convertedString;
  }

  const fontSizeConverter = (val) => {
    if (val === "1") {
      return 10;
    } else if (val === "2") {
      return 13;
    } else if (val === "3") {
      return 16;
    } else if (val === "4") {
      return 18;
    } else if (val === "5") {
      return 24;
    } else if (val === "6") {
      return 32;
    } else if (val === "7") {
      return 48;
    } else return val;
  };
  // const uploadFile=(e)=>{
  //    setFile(e.target.files[0])
  // }
  useEffect(() => {
    if (sessionStorage.getItem("timezone") === null) {
      axios
        .get("https://ipapi.co/json/?key=EJ1FHhnqyB6gRoAik4SRHnb8peb3wBAapbY93Q7TZkYZ0Tsu5R")
        .then((response) => {
          sessionStorage.setItem("timezone", response?.data?.timezone);
          GetAgreementDetails();
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else GetAgreementDetails();
  }, []);

  return (
    <Box className="web-view-wrapper">
      <Preloader showPreloader={showLoader} />
      <Grid container spacing={2} className="web-view-container">
        <Grid container className="web-view-content">
          <h3>Review and sign the Agreement</h3>
          <div className="agreement-view" dangerouslySetInnerHTML={{ __html: agreementContent }} />
          <Grid item xs={12} className="agreement_consent">
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  onChange={(e) => {
                    setAgreeTerms(e.target.checked);
                  }}
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={<CheckCircleIcon />}
                />
              }
              label="* I have carefully read this agreement and agree to the terms and conditions above."
            />
          </Grid>
          <Grid item xs={12} id="agreeTermsID"></Grid>
          <Grid item xs={6} sm={3}>
            <FormControl variant="standard" className="forms-control-webview">
              <label className="input-form-label" htmlFor="Address" id="demo-simple-select-label">
                Client 1*
              </label>
              <TextField
                id="firstName"
                className="input-textfield"
                size="small"
                variant="outlined"
                placeholder="Full Name"
                // value={firstName}
                onChange={(e) => setClientNameOne(e.target.value)}
                error={clientNameOneError}
                helperText={`${clientNameOneError ? clientNameOneErrorText : ""}`}
                type="text"
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={3}>
            <FormControl variant="standard" className="forms-control-webview">
              <label className="input-form-label" htmlFor="Address" id="demo-simple-select-label">
                Date*
              </label>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  value={new Date(date1)}
                  inputFormat="dd-MM-yyyy"
                  minDate={new Date()}
                  className={"inspection-filter-modal-select"}
                  sx={{
                    height: "40px !important",
                    '.MuiInputBase-root.MuiOutlinedInput-root': {
                      height: "40px !important",
                    },
                  }}
                  onChange={(newValue) => setDate1(newValue)}
                  error={date1Error}
                  helperText={`${date1Error ? date1ErrorText : ""}`}
                  renderInput={(params) => <TextField sx={{ height: "40px !important" }} {...params} size="small" />}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          {/* ============================================================================================== */}
          <Grid item xs={6} sm={3}>
            <FormControl variant="standard" className="forms-control-webview">
              <label className="input-form-label" htmlFor="Address" id="demo-simple-select-label">
                client 2
              </label>
              <TextField
                id="firstName"
                className="input-textfield"
                size="small"
                variant="outlined"
                placeholder="Full Name"
                onChange={(e) => setClientNameTwo(e.target.value)}
                error={clientNameTwoError}
                helperText={`${clientNameTwoError ? clientNameOneErrorText : ""}`}
                type="text"
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={3}>
            <FormControl variant="standard" className="forms-control-webview">
              <label className="input-form-label" htmlFor="Address" id="demo-simple-select-label">
                Date
              </label>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  value={new Date(date2)}
                  inputFormat="dd-MM-yyyy"
                  minDate={new Date()}
                  className={"inspection-filter-modal-select"}
                  onChange={(newValue) => setDate2(newValue)}
                  sx={{
                    height: "40px !important",
                    '.MuiInputBase-root.MuiOutlinedInput-root': {
                      height: "40px !important",
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ height: "40px !important" }}
                      inputProps={{ ...params.inputProps, placeholder: "hh:mm am/pm" }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          {/* below code might be required later */}
          {/* ================================================================================================= */}
          {/* test input */}
          {/* <Grid item xs={6} sm={3}>
            <FormControl variant="standard" className="forms-control-webview">
              <label
                className="input-form-label"
                htmlFor="Address"
                id="demo-simple-select-label"
              >
                pdf
              </label>
              <input id='inputPDF'
                  // ref={fileInput}
                  accept=".pdf" type="file" onChange={uploadFile}
      />           
            </FormControl>
          </Grid> */}
          {/* test input */}
          <Grid item xs={12} className="agreement-action">
            <div></div>
            <div className="right-btn-group">
              <Button onClick={validate} className="agree-btn">
                Agree
              </Button>
              <Button className="reject-btn" onClick={rejectAgreement}>
                <span className="reject-text">Reject</span>
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AgreementWebViewNSignature;
