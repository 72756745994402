import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { Grid, Button } from "@mui/material";
import Box from "@mui/material/Box";
import { apiCall } from "../../utils/action";
import { defaultTemplateValue } from "./DefaultTemplate";
import Placeholder from "../../common/placeholder/Placeholder";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Preloader from "../../helpers/Preloader";
import InlineEdit from "../../common/inlineEdit/InlineEdit";
import { checkForInvalidImages, convertFontToSpan } from "../../utils/services";
import FroalaWithoutDropdown from "../../common/htmlEditor/FroalaWithoutDropdown";

const froalaConfig = {
  spellCheck: true,
  heightMin: 500,
  heightMax: 510,
  imageUpload: true,
  pluginsEnabled: [
    "wordPaste",
    "spellChecker",
    "codeBeautifier",
    "fontFamily",
    "fontSize",
    "colors",
    "align",
    "lists",
    "image",
    "lineHeight",
    "quote",
    "link",
    "table",
    "fullscreen",
    "print",
    "codeView",
  ],
};

const toolbarButtonsConfig = {
  moreText: {
    buttons: ["bold", "underline", "italic", "fontFamily", "fontSize", "textColor", "backgroundColor", "clearFormatting"],
    align: "left",
    buttonsVisible: 5,
  },
  moreParagraph: {
    buttons: ["formatOLSimple", "formatUL", "alignCenter", "alignLeft", "alignRight", "outdent", "indent"],
    align: "left",
    buttonsVisible: 3,
  },
  moreRich: {
    buttons: ["insertLink", "insertImage", "insertTable"],
    align: "left",
    buttonsVisible: 3,
  },
  moreMisc: {
    buttons: ["undo", "redo", "html", "spellChecker", "selectAll"],
    align: "right",
    buttonsVisible: 0,
  },
};

const AgreementEditor = () => {
  const location = useLocation();
  let agreementId = location.state?.id;
  const editorRef = useRef();
  const [storedText, setStoredText] = useState("");
  const [showLoader, setShowLoader] = useState();
  const [isDefaultAgreement, setIsDefaultAgreement] = useState(0);
  let navigate = useNavigate();
  const [editorContent, setEditorContent] = useState({ innerHTML: "" });
  const [isCopy, setIsCopy] = useState(false);

  const handleChange = (name, content) => {
    setEditorContent((prevState) => ({ ...prevState, innerHTML: content }));
    setIsCopy(false);
  };

  const companyId = sessionStorage.getItem("company_id");
  const roleId = sessionStorage.getItem("role_id");
  const uuId = sessionStorage.getItem("user_id");
  let permission = roleId === "7" ? false : true;
  // reset the agreement
  const resetAgreement = (id) => {
    if (location.state?.edit) {
      GetAgreementDetails();
    } else setEditorContent((prevState) => ({ ...prevState, innerHTML: "" }));
    if (location.state?.edit && isDefaultAgreement) {
      editAgreement(id, "default");
    }
  };
  async function imageToBase64(url) {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }
  const validation = async (id, action) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(editorContent?.innerHTML, 'text/html');
    const images = doc.querySelectorAll('img');
    for (let img of images) {
      if (img.src.startsWith('blob:')) {
        try {
          const base64 = await imageToBase64(img.src);
          img.src = base64;
        } catch (error) {
          console.error("Error converting image to base64", error);
        }
      }
    }
    setEditorContent({ innerHTML: doc.body.innerHTML });
    const isValid = /^[\s;]*((&nbsp;)|(<p>(\s|&nbsp;|<br>)*<\/p>)|(<br>))*[\s;]*$/.test(doc.body.innerHTML);
    const { hasUnsupportedImages, hasCloudImages } = checkForInvalidImages(doc.body.innerHTML);
    let errorSpanEl = document.getElementById("agreement-error-message");
    errorSpanEl.innerHTML = "";
    if (isValid || doc.body.innerHTML?.length === 0 || doc.body.innerHTML?.length === 1) {
      errorSpanEl.innerHTML = "Agreement content is required";
    } else if (hasUnsupportedImages) {
      errorSpanEl.innerHTML = "Agreement content contains unsupported image formats. Only JPEG, JPG and PNG formats are supported.";
    }
    else if (hasCloudImages) {
      errorSpanEl.innerHTML = "Agreement content cannot contain images copied from online sources.";
    }
    else {
      if (action === "edit") editAgreement(id, null, doc.body.innerHTML);
      else addAgreement(doc.body.innerHTML);
    }
  };
  const GetAgreementDetails = () => {
    setShowLoader(true);
    const formdata = {
      company_id: companyId,
      user_id: uuId,
      role_id: roleId,
      agreement_id: agreementId,
    };
    apiCall(
      (response) => {
        const { data, message, success } = response.data;
        if (success) {
          setEditorContent({ innerHTML: data?.agreement_body });
          const editorInstance = editorRef?.current?.editor;
          const editorContent = editorInstance?.html?.get();
          if (editorContent) {
            editorInstance.html.set(data?.agreement_body, true);
          }
          setIsDefaultAgreement(data?.is_default);
          setStoredText(data?.name);
          setShowLoader(false);
        } else {
          toast.error(message);
        }
      },
      formdata,
      "GET_AGREEMENTS_LIST"
    );
  };

  const addAgreement = (data) => {
    toast.dismiss();
    setShowLoader(true);
    const formdata = {
      company_id: companyId,
      user_id: uuId,
      role_id: roleId,
      name: storedText,
      agreement_body: data,
    };
    apiCall(
      (response) => {
        const { message, success } = response.data;
        if (success) {
          toast.success(message);
          setShowLoader(false);
          // PALM 10151
          // Userpilot.track("Add agreement");
          navigate(`/settings/agreements`);
        } else {
          toast.error(message);
          setShowLoader(false);
        }
      },
      formdata,
      "ADD_AGREEMENT"
    );
  };

  const editAgreement = (id, action, data) => {
    toast.dismiss();
    setShowLoader(true);
    console.log("editorContent?.innerHTML", editorContent?.innerHTML);
    const formdata = {
      company_id: companyId,
      user_id: uuId,
      role_id: roleId,
      agreement_id: id,
      name: storedText,
      agreement_body: action == "default" ? defaultTemplateValue : data,
    };
    apiCall(
      (response) => {
        const { message, success } = response.data;
        if (success) {
          toast.success(message);
          setShowLoader(false);
          navigate(`/settings/agreements`);
        } else {
          toast.error(message);
          setShowLoader(false);
          console.log("else ", response.data.message);
        }
      },
      formdata,
      "EDIT_AGREEMENT"
    );
  };

  useEffect(() => {
    !location.state?.edit && setStoredText("Untitled");
    location.state?.edit && GetAgreementDetails();
  }, []);

  return (
    <>
      <Box sx={{ width: "100%", px: { xs: "0", sm: "20px", md: "80px", lg: "80px" } }} className="teams-container">
        <Preloader showPreloader={showLoader} />
        <Grid container>
          <Grid item xs={12} sm={9} className="editor-title-wrapper">
            <div>
              <h3 className="editor-title">{!permission ? storedText : <InlineEdit text={storedText} onSetText={(text) => setStoredText(text)} />}</h3>
              <p className="editor_details_subtitle">
                {location.state?.edit ? (
                  <span className="agreement_editor_title_text">
                    Edit the template using the placeholders on the right. Agreements are automatically sent to your customer after the appointment is scheduled. Go to{" "}
                    <span className="navigate_text" onClick={() => navigate("/settings/services")}>
                      Services
                    </span>{" "}
                    to assign agreements to services. Change what is automatically sent in{" "}
                    <span className="navigate_text" onClick={() => navigate("/notifications")}>
                      Notification settings
                    </span>
                    .{" "}
                  </span>
                ) : (
                  <span className="agreement_editor_title_text">
                    Add new template using the placeholders on the right. Agreements are automatically sent to your customer after the appointment is scheduled. Go to{" "}
                    <span className="navigate_text" onClick={() => navigate("/settings/services")}>
                      Services
                    </span>{" "}
                    to assign agreements to services. Change what is automatically sent in{" "}
                    <span className="navigate_text" onClick={() => navigate("/notifications")}>
                      Notification settings
                    </span>
                    .{" "}
                  </span>
                )}
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={3}></Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid className="editor-class" item xs={12} sm={9}>
            <FroalaWithoutDropdown
              showAI={false}
              copy={isCopy}
              commentData={editorContent}
              editorData={convertFontToSpan(editorContent)}
              editor={editorRef}
              id={"agreement-editor"}
              onChange={handleChange}
              froalaConfig={froalaConfig}
              toolbarButtonsConfig={toolbarButtonsConfig}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Placeholder setShowLoader={setShowLoader} editor={editorRef} isDefaultAgreement={isDefaultAgreement} isAgreementPlaceholder={true} />
          </Grid>
          <Grid item xs={12} className="agreement-action" style={{ paddingTop: "10px" }}>
            <span id="agreement-error-message"> </span>
          </Grid>
          {permission && (
            <Grid item xs={12} className="agreement-action">
              <Button
                className="agreement-btn"
                sx={{ textTransform: "none !important", ":hover": { backgroundColor: "#1E8970 !important" } }}
                onClick={location?.state?.edit ? () => validation(location.state.id, "edit") : validation}>
                Save agreement
              </Button>
              <span className="navlink" id="reset_to_default_agreement" onClick={() => resetAgreement(location?.state?.id)}>
                <span>Reset to default</span>
              </span>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default AgreementEditor;
