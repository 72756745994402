import axios from "axios";
import { API_CONSTANTS } from "../../../config/api-constants";

export const PtImportUpload = async (formData, setState) => {
  try {
    const token = sessionStorage.getItem("token");
    const impersinated = sessionStorage.getItem("impersinated");
    const impersonatorID = sessionStorage.getItem("impersonator_id");
    const tokenID = sessionStorage.getItem("token_id");
    const headers = impersinated
      ? {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          "impersonator-id": `${impersonatorID}`,
          "token-id": `${tokenID}`,
        }
      : {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        };
    const response = await axios.post(
      API_CONSTANTS.IMPORT_TEMPLATE_FILE,
      formData,
      {
        headers: headers,
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setState((prevState) => ({
            ...prevState,
            progressPercent: percentCompleted,
          }));
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};



export const SpectoraUpload = async (formData, setState) => {
  try {
    const token = sessionStorage.getItem("token");
    const impersinated = sessionStorage.getItem("impersinated");
    const impersonatorID = sessionStorage.getItem("impersonator_id");
    const tokenID = sessionStorage.getItem("token_id");
    const headers = impersinated
      ? {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          "impersonator-id": `${impersonatorID}`,
          "token-id": `${tokenID}`,
        }
      : {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        };
    const response = await axios.post(
      API_CONSTANTS.SPECTORA_UPLOAD,
      formData,
      {
        headers: headers,
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setState((prevState) => ({
            ...prevState,
            progressPercent: percentCompleted,
          }));
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};
